import React, { Component } from "react";
import request from "../../../core/api";
import defaultImage from "../../../assets/images/default-images-empty.png";
import "./index.scss";
import moment from "moment";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BookingDetailModal from "./components/bookingDetailModal";
import CancelModal from "./components/cancelModal";
import constant from "../../../core/constant";
import ReturnTopButton from "./../../shared/top-button";
import ScrollTopBtn from "./../../shared/scroll-button";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopId: props.match.params.id,
      listBooking: [],
      bookingDetail: {},
      isShowDetailModal: false,
      isShowCancelModal: false,
      hasBeenBookedShops: [],
      listBookedShop: [],
      currentShopData: {},
      shopRequiredData: {},
      // chosenShopDetail: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchBookedShops();
  }

  fetchListMenu(shopId) {
    return request({
      url: `shop/${shopId}/menu/`,
      method: "get",
    });
  }

  fetchListStaff(shopId) {
    return request({
      url: `shop/${shopId}/staff/`,
      method: "get",
    });
  }

  fetchListDiscount(shopId) {
    return request({
      url: `shop/${shopId}/discount/`,
      method: "get",
    });
  }

  // fetchShopDetail(shopId) {
  //   return request({
  //     url: `/shop/${shopId}/`,
  //     method: "get",
  //   });
  // }

  handleEventCollapse = (nodes) => {
    this.handleEventCollapse = function () {};

    nodes.forEach((item) => {
      item.addEventListener("show.bs.collapse", async () => {
        const { shopRequiredData } = this.state;
        const { itemId } = item.dataset;
        await this.setState({ listBooking: [] });

        const needRemovedStyleNode = document.querySelector(
          `.user-booking-history__list-item.accordion-item.is-loading`
        );
        if (needRemovedStyleNode) {
          needRemovedStyleNode.classList.remove("is-loading");
        }

        const node = document.querySelector(
          `.user-booking-history__list-item.accordion-item[href='#collapse${itemId}']`
        );
        node.classList.add("is-loading");

        // this.fetchShopDetail(itemId)
        //   .then(({ data }) => {
        //     this.setState({ chosenShopDetail: data });
        //   })
        //   .catch(() => {});

        if (!shopRequiredData[`${itemId}`]) {
          let promises = [];

          const fetchMenu = new Promise((resolve, reject) => {
            this.fetchListMenu(itemId).then((result) => {
              const menus = result.data;
              resolve({ menus });
            });
          });
          const fetchStaff = new Promise((resolve, reject) => {
            this.fetchListStaff(itemId).then((result) => {
              const staffs = result.data;
              resolve({ staffs });
            });
          });
          const fetchDiscount = new Promise((resolve, reject) => {
            this.fetchListDiscount(itemId).then((result) => {
              const discounts = result.data;
              resolve({ discounts });
            });
          });

          promises = [fetchMenu, fetchStaff, fetchDiscount];

          await Promise.all(promises).then(async (results) => {
            for await (const res of results) {
              await this.setState((prev) => ({
                shopRequiredData: {
                  ...prev.shopRequiredData,
                  [`${itemId}`]: {
                    ...prev.shopRequiredData[`${itemId}`],
                    ...res,
                  },
                },
              }));
            }
          });
        }
        this.fetchBooking(item.dataset.itemId).then((result) => {
          node.classList.remove("is-loading");
          this.setState({ listBooking: result.data });
        });
      });
      item.addEventListener("hide.bs.collapse", () => {
        const needRemovedStyleNode = document.querySelector(
          `.user-booking-history__list-item.accordion-item.is-loading`
        );
        if (needRemovedStyleNode) {
          needRemovedStyleNode.classList.remove("is-loading");
        }
        this.setState({ listBooking: [] });
      });
    });
  };

  fetchBookedShops = () => {
    request({
      url: "booking/shops/",
      method: "get",
    })
      .then((result) => {
        this.setState({ listBookedShop: result.data }, () => {
          const nodes = document.querySelectorAll(".js-collapse-node");
          this.handleEventCollapse(nodes);
        });
      })
      .catch((err) => {});
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  showBookingDetail = async (booking) => {
    await this.setState({
      bookingDetail: booking,
      currentShopData: this.state.shopRequiredData[booking.shop],
      isShowDetailModal: true,
    });
    // this.setState({ isShowDetailModal: true });
  };

  showBookingCancel = async (booking) => {
    await this.setState({ bookingDetail: booking });
    this.setState({ isShowCancelModal: true });
  };

  onCloseCancelModal = async (cancelledBooking) => {
    if (cancelledBooking) {
      this.setState({
        listBooking: this.state.listBooking.map((item) => {
          if (cancelledBooking.id === item.id) {
            item.status = "cancelled";
          }

          return item;
        }),
      });
    }

    this.setState({
      isShowCancelModal: false,
    });
  };

  gotoTop = () => {
    this.props.history.push(`/${this.props.match.params.id}/booking`);
  };

  fetchBooking = (shopId) => {
    return request({
      url: `shop/${shopId}/user_book/`,
      method: "get",
    });
  };

  render() {
    const {
      listBooking,
      bookingDetail,
      isShowDetailModal,
      isShowCancelModal,
      hasBeenBookedShops,
      listBookedShop,
      currentShopData,
      // chosenShopDetail,
    } = this.state;

    const { gotoTop } = this;
    // const { user } = this.props;

    const { showBookingDetail, showBookingCancel, onCloseCancelModal } = this;
    return (
      <>
        <ScrollTopBtn />
        <div className="user-booking-history fade-out">
          <div className="user-booking-history__wrapper">
            <ReturnTopButton onClick={gotoTop} />
            <h2 className="user-booking-history__title">予約一覧</h2>
            <ul
              className="user-booking-history__list-booked-shop accordion"
              id="accordionExample"
            >
              {listBookedShop.length
                ? listBookedShop.map((item) => (
                    <>
                      <li
                        className="user-booking-history__list-item accordion-item"
                        key={item.id}
                        data-bs-toggle="collapse"
                        href={`#collapse${item.id}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapse-area"
                      >
                        <figure className="user-booking-history__shop-image">
                          <img
                            src={item.image || defaultImage}
                            alt="cippo-booking"
                          />
                        </figure>
                        <p className="user-booking-history__shop-name">
                          {item.name}
                        </p>
                        <span
                          className="accordion-button collapsed user-booking-history__accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${item.id}`}
                          aria-expanded="false"
                          // aria-controls="collapseTwo"
                        ></span>
                      </li>
                      <div
                        className="collapse js-collapse-node"
                        id={`collapse${item.id}`}
                        data-bs-parent="#accordionExample"
                        data-item-id={item.id}
                      >
                        {listBooking.length ? (
                          <ul className="user-booking-history__list-booking">
                            {listBooking.map((booking) => (
                              <li
                                key={booking.id}
                                className="user-booking-history__booking-item booking-item"
                              >
                                <div className="booking-item__main">
                                  <div className="booking-item__booking-status">
                                    <p className="booking-item__status-label">
                                      {booking.status ===
                                      constant.bookingStatus.waiting
                                        ? "予約待ち"
                                        : booking.status ===
                                          constant.bookingStatus.cancelled
                                        ? "予約キャンセル"
                                        : "予約確定"}
                                    </p>

                                    <p className="booking-item__status-updated-date">
                                      更新日：
                                      {moment(booking.updated).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </p>
                                  </div>
                                  <div className="booking-item__shop-name">
                                    <span>
                                      {hasBeenBookedShops.find(
                                        (item) => item.id === booking.shop
                                      )?.name || ""}
                                    </span>
                                  </div>
                                  <div className="booking-item__visit-date">
                                    {![
                                      constant.bookingStatus.cancelled,
                                      constant.bookingStatus.approved,
                                    ].includes(booking.status) ? (
                                      <p>
                                        ※ここの店舗は予約確定次第通知がきます
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p>
                                      来店日時：
                                      {moment(booking.start_time).format(
                                        "YYYY年MM月DD日 (dd) HH:mm"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="booking-item__buttons">
                                  <button
                                    type="button"
                                    className="booking-item__booking-content-button"
                                    onClick={() => showBookingDetail(booking)}
                                  >
                                    予約内容を見る
                                  </button>
                                  <button
                                    type="button"
                                    disabled={
                                      booking.status ===
                                      constant.bookingStatus.cancelled
                                    }
                                    className="booking-item__booking-cancel-button"
                                    onClick={() => showBookingCancel(booking)}
                                  >
                                    <span className="material-icons close-btn">
                                      close
                                    </span>
                                    {booking.status ===
                                    constant.bookingStatus.cancelled
                                      ? "キャンセル済み"
                                      : "キャンセルする"}
                                  </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ))
                : ""}
            </ul>
          </div>
          {isShowDetailModal ? (
            <BookingDetailModal
              data={bookingDetail}
              shopData={currentShopData}
              {...this.props}
              onClose={() => this.setState({ isShowDetailModal: false })}
            />
          ) : (
            ""
          )}
          {isShowCancelModal ? (
            <CancelModal data={bookingDetail} onClose={onCloseCancelModal} />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
