import React from "react";
import Dialog from "../../../../shared/dialog";
import moment from "moment";
import MomentUtils from "@date-io/moment"; // choose your lib
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ja";
import utils from "./../../../../../core/utils";
import { schemaPercentage, schemaFixed, schemaDate } from "../validate";
import cloneDeep from "lodash/cloneDeep";
import MyButton from "./../../../../shared/buttons/primary";

moment.locale("ja");

class DiscountDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisabled: false,
      errorMessages: [],
      discountData: {
        name: "",
        value: "",
        code: "",
        type: "Fixed",
        valid_from: moment(moment(), moment.ISO_8601).format(),
        valid_to: moment(moment(), moment.ISO_8601).format(),
        shop: window.store.getState().auth.user.id,
      },
    };
  }

  componentDidMount() {
    const data = window._.cloneDeep(this.props.data);
    data.value = data.value.toString().split(".")[0];

    this.setState((prev) => ({
      ...prev,
      discountData: {
        ...prev.discountData,
        ...data,
      },
    }));
  }

  addScrollEvent = (node) => {
    this.addScrollEvent = function () {};

    node.addEventListener("scroll", () => {
      document.activeElement.blur();
    });
  };

  componentDidUpdate() {
    const discountDialogNode = document.querySelector("#edit-discount-dialog");
    if (window.store.getState().booking.isMobile && discountDialogNode) {
      this.addScrollEvent(discountDialogNode);
    }
  }

  validateOnBlur = (e) => {
    let errorList;
    const {
      error: { details },
    } =
      this.state.discountData.type === "Fixed"
        ? schemaFixed.validate({
            [e.target.name]: e.target.value,
          })
        : schemaPercentage.validate({
            [e.target.name]: e.target.value,
          });
    errorList = details;

    let errorMsgState = this.state.errorMessages;
    const error = errorList.find(
      (item) => item.context.label === e.target.name
    );

    if (!error) {
      const errorIdx = errorMsgState.findIndex(
        (error) => error.name === e.target.name
      );
      if (errorIdx !== -1) {
        errorMsgState.splice(errorIdx, 1);
      }
    } else {
      const item = errorMsgState.find(
        (item) => item.name === error.context.label
      );

      if (!item) {
        errorMsgState.push({
          name: e.target.name,
          message: error.message,
        });
      } else {
        errorMsgState = errorMsgState.map((item) => {
          if (item.name === error.context.label) {
            return {
              name: e.target.name,
              message: error.message,
            };
          }

          return item;
        });
      }
    }

    this.setState((prev) => ({
      ...prev,
      errorMessages: errorMsgState,
    }));
  };

  handleStartTimeChange = (date) => {
    if (moment(date).isAfter(moment(this.state.discountData.valid_to))) {
      this.setState((prevState) => ({
        ...prevState,
        discountData: {
          ...prevState.discountData,
          valid_to: moment(date, moment.ISO_8601).format(),
        },
      }));
    }

    this.setState((prevState) => ({
      ...prevState,
      discountData: {
        ...prevState.discountData,
        valid_from: moment(date, moment.ISO_8601).format(),
      },
    }));
  };

  handleEndTimeChange = (date) => {
    const { error } = schemaDate.validate({
      valid_from: moment(this.state.discountData.valid_from).toDate(),
      valid_to: moment(date).toDate(),
    });

    if (error) {
      let errMessages = this.state.errorMessages;
      let index = errMessages.findIndex((err) => err.name === "valid_to");

      if (index === -1) {
        errMessages.push({
          name: "valid_to",
          message: error.details[0].message,
        });
      }
      this.setState((prev) => ({
        ...prev,
        errorMessages: errMessages,
      }));
    } else {
      let errMessages = this.state.errorMessages;
      let index = errMessages.findIndex((err) => err.name === "valid_to");
      if (index !== -1) {
        errMessages.splice(index, 1);
      }
      this.setState((prev) => ({
        ...prev,
        errorMessages: errMessages,
      }));
    }

    this.setState((prevState) => ({
      ...prevState,
      discountData: {
        ...prevState.discountData,
        valid_to: moment(date, moment.ISO_8601).format(),
      },
    }));
  };

  onCloseRequest = (data) => {
    this.props.onCloseRequest(data);
  };
  editMenu = async () => {
    await this.setState({ isBtnDisabled: true });

    const { discountData } = this.state;

    const validate =
      discountData.type === "Fixed"
        ? schemaFixed.validate(discountData)
        : schemaPercentage.validate(discountData);

    const validateLabels = ["name", "value", "valid_to"];

    let errors;
    errors = validate.error.details.filter((item) =>
      validateLabels.includes(item.context.label)
    );

    errors = errors.map((item) => {
      return {
        name: item.context.label,
        message: item.message,
      };
    });

    if (errors.length) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: errors,
      }));

      this.setState({ isBtnDisabled: false });
      return;
    }

    const submitData = cloneDeep(this.state.discountData);

    submitData.valid_from = moment(submitData.valid_from).format(
      "YYYY-MM-DD HH:mm"
    );
    submitData.valid_to = moment(submitData.valid_to).format(
      "YYYY-MM-DD HH:mm"
    );

    window
      .axios({
        url: `discount/${this.state.discountData.id}/`,
        method: "put",
        data: submitData,
      })
      .then((result) => {
        this.props.onCloseRequest(result.data);
      });
  };

  disabledEndTime = (date) => {
    const startTime = moment(this.state.discountData.valid_from).format(
      "YYYY-MM-DD"
    );
    const endTime = moment(date).format("YYYY-MM-DD");
    return moment(endTime).isBefore(moment(startTime));
  };

  onUnitChange = (e) => {
    this.setState((prev) => {
      return {
        ...prev,
        discountData: {
          ...prev.discountData,
          type: e.target.value,
        },
      };
    });
  };

  render() {
    const { validateOnBlur, props, onCloseRequest, editMenu, onUnitChange } =
      this;
    const { discountData, errorMessages } = this.state;
    return (
      <Dialog
        id="edit-discount-dialog"
        className={"booking-dialog menu-dialog"}
        title={"割引編集"}
        isShow={props.isShow}
        onCloseRequest={() => onCloseRequest({})}
        closeOnOverlayClick={false}
      >
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">割引名</label>
          </div>
          <div className="booking-form__control">
            <input
              className="form-control"
              placeholder="割引名"
              value={discountData.name}
              name="name"
              maxLength="255"
              autoFocus
              onBlur={validateOnBlur}
              onChange={(e) => {
                this.setState((prev) => {
                  return {
                    ...prev,
                    discountData: {
                      ...prev.discountData,
                      name: !e.target.value.trim() ? "" : e.target.value,
                    },
                  };
                });
              }}
            />
            {errorMessages.find((item) => item.name === "name") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "name").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">割引価格</label>
          </div>
          <div className="booking-form__control">
            <div className="form-time">
              <input
                inputMode="decimal"
                className="form-control me-2"
                onInput={(e) => {
                  if (discountData.type === "Fixed") {
                    if (+e.target.value < 1) {
                      e.target.value = "";
                    }
                  }
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                value={discountData.value}
                name="value"
                placeholder="割引価格"
                onBlur={validateOnBlur}
                maxLength="15"
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      discountData: {
                        ...prev.discountData,
                        value: e.target.value,
                      },
                    };
                  })
                }
              />
              <select
                className="form-select"
                onChange={onUnitChange}
                value={discountData.type}
              >
                <option value="Percentage">%</option>
                <option value="Fixed">円</option>
              </select>
            </div>
            {discountData.value && discountData.type === "Fixed" ? (
              <p style={{ fontSize: "14px", marginTop: "8px" }}>
                ￥
                {utils.formatMoney(Number(discountData.value), 0, 3, ",", ".")}
              </p>
            ) : (
              ""
            )}
            {errorMessages.find((item) => item.name === "value") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "value").message}
              </p>
            )}
          </div>
        </div>
        <div className="mb-3 booking-form__row required">
          <div className="booking-form__label">
            <label
              htmlFor="customerTime"
              className="form-label user-select-none"
            >
              時間
            </label>
          </div>
          <div className="booking-form__control">
            <div className="date-time-wrapper">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  value={moment(this.state.discountData.valid_from).toDate()}
                  onChange={this.handleStartTimeChange}
                  okLabel={"完了"}
                  cancelLabel={"キャンセル"}
                />

                <span>~</span>

                <DateTimePicker
                  value={moment(this.state.discountData.valid_to).toDate()}
                  shouldDisableDate={this.disabledEndTime}
                  onChange={this.handleEndTimeChange}
                  okLabel={"完了"}
                  cancelLabel={"キャンセル"}
                />
              </MuiPickersUtilsProvider>
            </div>
            {errorMessages.find((item) => item.name === "valid_to") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "valid_to").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__btns">
          {/* <span
            type="button"
            className="cancel-button me-2"
            disabled={this.state.isBtnDisabled}
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </span> */}
          <MyButton
            className="me-3"
            myVariant="cancel"
            disabled={this.state.isBtnDisabled}
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </MyButton>

          <MyButton
            myVariant="create"
            isLoading={this.state.isBtnDisabled}
            onClick={editMenu}
          >
            確定
          </MyButton>
          {/* <span
            type="button"
            disabled={this.state.isBtnDisabled}
            className="add-button me-2"
            onClick={editMenu}
          >
            確定
          </span> */}
        </div>
      </Dialog>
    );
  }
}

export default DiscountDialog;
