import iconBook from "../../../assets/icons/ic-book.png";
import defaultMenuImage from "./../../../assets/images/default-images-empty.png";
import "./index.scss";
import truncate from "lodash/truncate";
import utils from "../../../core/utils";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReturnTopButton from "../../shared/top-button";
import moment from "moment-timezone";
import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";
// import ScrollTopBtn from "./../../shared/scroll-button";

//eslint-disable-next-line
Number.prototype.format = function (n, x, s, c) {
  //eslint-disable-next-line
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};
class Content extends Component {
  constructor(props) {
    super(props);

    this.dispatch = props.dispatchActions;

    this.state = {
      shopId: props.match.params.id,
      listMenu: [],
      listChosenMenuId: [],
      listChosenMenu: [],
      listDiscount: [],
      total:
        JSON.parse(localStorage.getItem(`${props.match.params.id}`))?.total ||
        "",
      discount:
        JSON.parse(localStorage.getItem(`${props.match.params.id}`))
          ?.discount || "",
      renderData: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const listMenuMap = this.renderListMenu();
    this.setState({ renderData: listMenuMap });
  }

  renderListMenu = () => {
    const {
      shopMenus,
      shopDiscounts,
      shopStaffs,
      userBookingData: { chosenStaff },
    } = this.props.user;

    let filterdShopMenu = cloneDeep(shopMenus);

    if (chosenStaff) {
      const staffMenus = shopStaffs.find((s) => s.id === chosenStaff).menus;
      filterdShopMenu = staffMenus;
    }

    const renderData = filterdShopMenu.map((item) => {
      const itemClone = cloneDeep(item);
      const discount = shopDiscounts.find((d) => d.id === itemClone.discount);
      if (discount) {
        let validTo = shopDiscounts.find(
          (d) => d.id === itemClone.discount
        )?.valid_to;

        const now = moment().format("YYYY-MM-DDTHH:mm");

        if (moment(now).isSameOrBefore(validTo)) {
          itemClone.showDiscount = true;
        } else {
          itemClone.showDiscount = false;
        }
      } else {
        itemClone.showDiscount = false;
      }

      return itemClone;
    });

    return renderData;
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  removeMenu = (menu) => {
    const listChosen = this.props.user.userBookingData.chosenMenu.split(",");
    const index = listChosen.findIndex((item) => +item === menu.id);
    listChosen.splice(index, 1);

    const totalMoney = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.price));
      }, 0);

    const totalDuration = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.duration));
      }, 0);

    const totalDiscount = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        const discount = this.props.user.shopDiscounts.find(
          (item) => curr.discount === item.id
        );

        let amount = acc;

        if (discount) {
          if (discount.type === "Percentage") {
            amount += (+discount.value * +curr.price) / 100;
          } else {
            amount += +discount.value;
          }
        }
        return amount;
      }, 0);

    const userBookingData = {
      ...this.props.user.userBookingData,
      total: totalMoney,
      duration: totalDuration,
      discount: totalDiscount,
      chosenMenu: listChosen.join(","),
    };

    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  choseMenu = (menu) => {
    let listChosen;
    if (!this.props.user.userBookingData.chosenMenu) {
      listChosen = [menu.id];
    } else {
      listChosen = this.props.user.userBookingData.chosenMenu.split(",");
      if (listChosen.find((id) => menu.id === +id)) {
        return;
      }
      listChosen.push(menu.id);
    }

    const totalMoney = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.price));
      }, 0);

    const totalDuration = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.duration));
      }, 0);

    const totalDiscount = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        const discount = this.props.user.shopDiscounts.find(
          (item) => curr.discount === item.id
        );

        let amount = acc;

        if (
          discount &&
          moment(
            moment(discount.valid_to).format("YYYY-MM-DD HH:mm")
          ).isSameOrAfter(
            moment(
              this.props.user.userBookingData.chosenTime || new Date()
            ).format("YYYY-MM-DD HH:mm")
          ) &&
          moment(
            moment(discount.valid_from).format("YYYY-MM-DD HH:mm")
          ).isSameOrBefore(
            moment(
              this.props.user.userBookingData.chosenTime || new Date()
            ).format("YYYY-MM-DD HH:mm")
          )
        ) {
          if (discount.type === "Percentage") {
            amount += (+discount.value * +curr.price) / 100;
          } else {
            amount += +discount.value;
          }
        }
        return amount;
      }, 0);

    const userBookingData = {
      ...this.props.user.userBookingData,
      total: totalMoney,
      discount: totalDiscount,
      duration: totalDuration,
      chosenMenu: listChosen.join(","),
    };

    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  gotoTop = () => {
    this.props.history.push(`/${this.props.match.params.id}/booking`);
  };

  render() {
    const { gotoTop, choseMenu, removeMenu } = this;
    const { renderData } = this.state;
    const { shopMenus, userBookingData, shopDiscounts } = this.props.user;
    const { total, discount, duration } = this.props.user.userBookingData;

    let chosenMenu = [];
    if (userBookingData.chosenMenu) {
      chosenMenu = userBookingData.chosenMenu
        .split(",")
        .map((item) => shopMenus.find((menu) => menu.id === +item));
    }

    return (
      <>
        <div
          id="user-booking-menus"
          className="user-staffs user-menus fade-out"
        >
          <div className="user-menus__wrapper">
            <div className="user-staffs__ttl user-menus__ttl">
              <section>
                <figure>
                  <img src={iconBook} alt="cippo-booking"></img>
                </figure>
                <p>メニューを選択する</p>
              </section>
              <span className="user-menus__btn-return-top" onClick={gotoTop}>
                TOPへ戻る
              </span>
            </div>
            <div className="user-menus__ctn-wrapper">
              <ul className="user-menus__list-menu">
                {renderData.map((item) => (
                  <li
                    key={item.id}
                    // className="user-menus__menu-info"
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    className={[
                      "user-menus__menu-info",
                      chosenMenu.find((menu) => menu.id === item.id)
                        ? "user-menus__menu-info--selected"
                        : "",
                    ].join(" ")}
                  >
                    <div>
                      {item.image ? (
                        <figure className="user-menus__menu-image">
                          <img
                            // src={item.image || defaultMenuImage}
                            src={
                              item.media_type === "image"
                                ? item.image || defaultMenuImage
                                : utils.getIdLinkYoutube(item.image)
                                ? `https://img.youtube.com/vi/${
                                    utils.getIdLinkYoutube(item.image)[1]
                                  }/0.jpg`
                                : defaultMenuImage
                            }
                            alt="cippo-booking"
                          ></img>
                        </figure>
                      ) : (
                        ""
                      )}
                      <div className="user-menus__menu-content">
                        <p className="user-menus__menu-ttl">{item.name}</p>
                        <p className="user-menus__menu-desc">
                          {window._.truncate(item.detail, {
                            length: 150,
                            omission: "...",
                          })}
                        </p>
                      </div>
                      <div className="user-menus__time-and-price">
                        <div className="user-menus__time">
                          <span className="material-icons">schedule</span>
                          <p>{item.duration}分</p>
                        </div>
                        <div className="user-menus__price">
                          ¥
                          {truncate(
                            utils.formatMoney(
                              Number(item.price),
                              0,
                              3,
                              ",",
                              "."
                            ),
                            {
                              length: 15,
                              omission: "...",
                            }
                          )}
                        </div>
                        <span
                          className="user-menus__chose-btn"
                          onClick={() => choseMenu(item)}
                        >
                          選択する
                        </span>
                      </div>
                    </div>

                    {item.showDiscount ? (
                      <div className="user-menus__discount-info">
                        <p>
                          ※ 割引：
                          {
                            shopDiscounts.find((it) => it.id === item.discount)
                              .name
                          }{" "}
                          (
                          {Number(
                            shopDiscounts.find((it) => it.id === item.discount)
                              .value
                          ).toFixed(0)}{" "}
                          {shopDiscounts.find((it) => it.id === item.discount)
                            .type === "Fixed"
                            ? "円"
                            : "%"}
                          )
                        </p>
                        <p>
                          (
                          {moment(
                            shopDiscounts.find((it) => it.id === item.discount)
                              .valid_from
                          ).format("YYYY/MM/DD HH:mm")}{" "}
                          -{" "}
                          {moment(
                            shopDiscounts.find((it) => it.id === item.discount)
                              .valid_to
                          ).format("YYYY/MM/DD HH:mm")}
                          )
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
              <div className="user-menus__cart-and-charge">
                <section>
                  <div>
                    <div className="user-menus__total-estimate">
                      <p className="user-menus__time-estimate">
                        <span>予想時間</span>
                        <span>{duration}分</span>
                      </p>
                      <p className="user-menus__price-estimate">
                        <span>金額</span>
                        <span>
                          ¥
                          {total
                            ? utils.formatMoney(Number(total), 0, 3, ",", ".")
                            : 0}
                        </span>
                      </p>
                      <p className="user-menus__discount-estimate">
                        <span>割引</span>
                        <span>
                          ¥
                          {discount
                            ? utils.formatMoney(
                                Number(discount),
                                0,
                                3,
                                ",",
                                "."
                              )
                            : 0}
                        </span>
                      </p>
                    </div>
                    <p className="user-menus__money-estimate">
                      <span>見積金額</span>
                      <span>
                        ¥
                        {total || discount
                          ? utils.formatMoney(
                              Number(total - discount),
                              0,
                              3,
                              ",",
                              "."
                            )
                          : 0}
                      </span>
                    </p>
                  </div>

                  <button
                    disabled={!chosenMenu.length}
                    className="user-menus__confirm-btn"
                    onClick={gotoTop}
                  >
                    確定
                  </button>
                </section>

                <ul className="user-menus__chosen-menus chosen-menus custom-style-scrollbar">
                  {chosenMenu.map((menu) => (
                    <li key={menu.id}>
                      <span
                        className="material-icons chosen-menus__close-btn"
                        onClick={() => removeMenu(menu)}
                      >
                        close
                      </span>
                      <p className="chosen-menus__name">{menu.name}</p>
                      <p className="chosen-menus__price">
                        ¥{utils.formatMoney(Number(menu.price), 0, 3, ",", ".")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <ReturnTopButton size="large" onClick={gotoTop} />
          </div>
        </div>
        {/* <ScrollTopBtn /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
