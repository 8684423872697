import React, { PureComponent } from "react";
import Modal from "../../../shared/dialog";
import request from "../../../../core/api";

class cancelModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisabled: false,
    };
  }
  cancelBooking = async () => {
    await this.setState({ isBtnDisabled: true });
    request({
      url: `booking/${this.props.data.id}/`,
      method: "patch",
      data: {
        status: "cancelled",
      },
    }).then((result) => {
      this.props.onClose(result.data);
    });
  };
  render() {
    return (
      <Modal
        id="booking-cancel-modal"
        className={"booking-cancel-modal"}
        title={"予約キャンセル"}
        isShow={true}
        closeByEscKey={true}
        showCloseButton={true}
        onCloseRequest={() => this.props.onClose()}
        closeOnOverlayClick={true}
      >
        <p
          style={{
            marginBottom: "1rem",
            fontSize: "12px",
            textAlign: "center",
            marginTop: "1.5rem",
          }}
        >
          キャンセルしてよろしいですか？
        </p>
        <button
          disabled={this.state.isBtnDisabled}
          type="button"
          onClick={this.cancelBooking}
        >
          確定
        </button>
      </Modal>
    );
  }
}

export default cancelModal;
