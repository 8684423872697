import Immutable from "seamless-immutable";
import * as types from "./types";

const initialState = Immutable({
  shopMenus: [],
  shopStaffs: [],
  shopDiscounts: [],
  isLoadMenuDone: false,
  isLoadStaffDone: false,
  isLoadDiscountDone: false,
  isLoadShopDetailDone: false,
  isLoadUserBookingDataDone: false,
  confirmWhenRemove: true,
  shopDetail: {},
  userBookingData: {},
  isUserChosenTime: false,
  userToken: localStorage.getItem("uAccessToken") || "",
  userRefreshToken: localStorage.getItem("uRefreshToken") || "",
  userInfo: JSON.parse(localStorage.getItem("uInfo")) || {},
});

const setShopMenu = (action, state) => {
  const { payload: shopMenus } = action;
  return state.merge({ shopMenus, isLoadMenuDone: true });
};

const setShopStaff = (action, state) => {
  const { payload: shopStaffs } = action;
  return state.merge({ shopStaffs, isLoadStaffDone: true });
};

const setShopDiscount = (action, state) => {
  const { payload: shopDiscounts } = action;
  return state.merge({ shopDiscounts, isLoadDiscountDone: true });
};

const setUserBookingData = (action, state) => {
  const {
    payload: { value },
  } = action;
  return state.merge({ userBookingData: value });
};

const setShopDetail = (action, state) => {
  const { payload: shopDetail } = action;
  let confirmWhenRemove = sessionStorage.getItem("needConfirmWhenRemove");

  let userBookingData;

  if (confirmWhenRemove === null) {
    confirmWhenRemove = true;
    sessionStorage.setItem("needConfirmWhenRemove", true);
  } else {
    confirmWhenRemove = JSON.parse(confirmWhenRemove);
  }

  userBookingData = {
    chosenMenu: "",
    total: "",
    discount: "",
    duration: "",
    chosenTime: "",
    isUserChosenTime: false,
  };
  return state.merge({
    shopDetail,
    userBookingData,
    isLoadShopDetailDone: true,
    isLoadUserBookingDataDone: true,
    confirmWhenRemove: confirmWhenRemove,
  });
};

const setUserToken = (action, state) => {
  const { payload } = action;

  localStorage.setItem("uAccessToken", payload);

  return state.merge({
    userToken: payload,
  });
};

const setUserInfo = (action, state) => {
  const { payload } = action;

  localStorage.setItem("uInfo", JSON.stringify(payload));

  return state.merge({
    userInfo: payload,
  });
};

const setUserRefreshToken = (action, state) => {
  const { payload } = action;

  localStorage.setItem("uRefreshToken", payload);

  return state.merge({
    userRefreshToken: payload,
  });
};

const signOut = (action, state) => {
  localStorage.clear();
  sessionStorage.clear();

  return state.merge({
    userToken: "",
    userInfo: {},
    userBookingData: {},
  });
};

const setConfirmWhenRemove = (action, state) => {
  sessionStorage.setItem("needConfirmWhenRemove", action.payload);
  return state.merge({
    confirmWhenRemove: action.payload,
  });
};

const dispatch = {
  [types.SET_SHOP_MENU]: setShopMenu,
  [types.SET_SHOP_STAFF]: setShopStaff,
  [types.SET_SHOP_DISCOUNT]: setShopDiscount,
  [types.SET_USER_BOOKING_DATA]: setUserBookingData,
  [types.SET_SHOP_DETAIL]: setShopDetail,
  [types.SET_USER_TOKEN]: setUserToken,
  [types.SET_USER_REFRESH_TOKEN]: setUserRefreshToken,
  [types.SET_USER_INFO]: setUserInfo,
  [types.SIGN_OUT]: signOut,
  [types.SET_CONFIRM_WHEN_REMOVE]: setConfirmWhenRemove,
};

const reducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }
  return typeof dispatch[action.type] === "function"
    ? dispatch[action.type](action, state)
    : state;
};

export default reducer;
