import Joi from "joi";

const schema = Joi.object({
  userAccount: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "ユーザー名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  userPassword: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "パスワードを入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

export default schema;
