import Immutable from "seamless-immutable";
import * as types from "./types";

const initialState = Immutable({
  simpleCalendarChosenDate: "",
  isMobile: sessionStorage.getItem("isMobile")
    ? JSON.parse(sessionStorage.getItem("isMobile"))
    : false,
  warningHasShown: sessionStorage.getItem("warningHasShown")
    ? JSON.parse(sessionStorage.getItem("warningHasShown"))
    : false,
});

const setSimpleCalendarChosenDate = (action, state) => {
  const { payload } = action;
  return state.merge({ simpleCalendarChosenDate: payload });
};

const setIsMobile = (action, state) => {
  const { payload } = action;
  sessionStorage.setItem("isMobile", payload);
  return state.merge({ isMobile: payload });
};

const setWarningShowState = (action, state) => {
  const { payload } = action;
  sessionStorage.setItem("warningHasShown", payload);
  return state.merge({ warningHasShown: payload });
};

const dispatch = {
  [types.SET_SIMPLE_CALENDAR_CHOSEN_DATE]: setSimpleCalendarChosenDate,
  [types.SET_IS_MOBILE]: setIsMobile,
  [types.SET_WARNING_SHOW_STATE]: setWarningShowState,
};

const reducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }
  return typeof dispatch[action.type] === "function"
    ? dispatch[action.type](action, state)
    : state;
};

export default reducer;
