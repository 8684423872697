import request from "./../../core/api";

export const getListMenu = (shopId) => {
  return request({
    url: `/shop/${shopId}/menu/`,
    method: "get",
  });
};

export const getListStaff = (shopId) => {
  return request({
    url: `/shop/${shopId}/staff/`,
    method: "get",
  });
};
