import React, { Component } from "react";
import moment from "moment";
import "./index.scss";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";

class MultiCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendars: [],
      currentMonth: "",
    };
  }

  renderHeader = (date) => {
    return (
      <div className="multi-calendar__header">
        {moment(date).format("YYYY年MM月")}
      </div>
    );
  };

  renderDay = () => {
    const startDay = moment().startOf("month").startOf("week");
    const days = [];
    for (let i = 0; i < 7; ++i) {
      const day = startDay.clone().add(i, "day");
      days.push(
        <div key={day} className="multi-calendar__day">
          {day.format("dd")}
        </div>
      );
    }

    return <div className="multi-calendar__days-row">{days}</div>;
  };

  onClick = (date) => {
    this.props.onChoseDate(date);
  };

  renderDate = (date) => {
    const startDay = moment(date).clone().startOf("month").startOf("week");
    const endDay = moment(date).clone().endOf("month").endOf("week");
    const day = startDay.clone();
    const calendar = [];
    const weeks = [];

    while (day.isBefore(endDay, "day")) {
      const dates = [];
      for (let i = 0; i < 7; ++i) {
        let disabled = false;
        let style = "";
        const dayClone = day.clone();

        const isToday = moment(day.format("YYYY-MM-DD")).isSame(
          moment().format("YYYY-MM-DD")
        );
        const isPast = moment(day.format("YYYY-MM-DD")).isBefore(
          moment().format("YYYY-MM-DD")
        );
        const isChosenDate = moment(
          this.props.chosenDate.format("YYYY-MM-DD")
        ).isSame(dayClone.format("YYYY-MM-DD"));

        if (this.props.disabledDate) {
          disabled = this.props.disabledDate(dayClone);
        }

        if (this.props.styledDate) {
          style = this.props.styledDate(dayClone);
        }

        dates.push(
          <button
            key={nanoid()}
            type="button"
            disabled={disabled}
            onClick={(e) => this.onClick(dayClone, e)}
            className={[
              "multi-calendar__date",
              isToday ? "multi-calendar__date--current-date" : "",
              isPast ? "multi-calendar__date--past-date" : "",
              isChosenDate ? "multi-calendar__date--chosen-date" : "",
              style ? style : "",
            ]
              .join(" ")
              .toString()
              .replace(/ +(?= )/g, "")
              .trim()}
          >
            {moment(day).clone().format("DD")}
          </button>
        );

        day.add(1, "day");
      }
      weeks.push(
        <div key={nanoid()} className="multi-calendar__week">
          {dates}
        </div>
      );
    }

    calendar.push(
      <div key={nanoid()} className="multi-calendar__body">
        {weeks}
      </div>
    );
    return calendar;
  };

  render() {
    const { value, numberOfCalendar, className } = this.props;
    const { renderHeader, renderDay, renderDate } = this;
    return (
      <div className={`multi-calendar ${className || ""}`.trim()}>
        {Array(numberOfCalendar).length
          ? Array(numberOfCalendar)
              .fill(0)
              .map((item, index) => (
                <div key={index} className="multi-calendar__calendar">
                  {renderHeader(moment(value).add(index, "month"))}
                  {renderDay()}
                  {renderDate(moment(value).add(index, "month"))}
                </div>
              ))
          : ""}
      </div>
    );
  }
}

MultiCalendar.propTypes = {
  className: PropTypes.string,
};

export default MultiCalendar;
