import React, { Component } from "react";
import request from "../../../core/api";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import utils from "../../../core/utils";
import { bindActionCreators } from "redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import "./index.scss";
import GuideModal from "./guide-modal";

class BookingSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopId: props.match.params.id,
      bookingId: props.match.params.bookingId,
      bookingData: {},
      isShowGuideModal: true,
    };
  }

  componentDidMount() {
    this.fetchBookingDetail(this.state.bookingId).then((result) => {
      this.setState({ bookingData: result.data });
    });
  }

  fetchBookingDetail = (bookingId) => {
    return request({
      url: `booking/${bookingId}/`,
      method: "get",
    });
  };

  goToBookingHistory = () => {
    this.props.history.push(`/${this.state.shopId}/booking/history`);
  };

  render() {
    const { bookingData, isShowGuideModal } = this.state;
    const { shopMenus, shopDiscounts, shopDetail } = this.props.user;
    const { goToBookingHistory } = this;

    let totalMoney = "";
    let totalDiscount = "";

    if (bookingData.hasOwnProperty("menu")) {
      totalMoney = bookingData.menu
        .map((item) => shopMenus.find((menu) => menu.id === item.id))
        .reduce((acc, curr) => {
          return (acc += Number(curr.price));
        }, 0);

      totalDiscount = bookingData.menu
        .map((item) => shopMenus.find((menu) => menu.id === item.id))
        .reduce((acc, curr) => {
          const discount = shopDiscounts.find(
            (item) => curr.discount === item.id
          );

          let amount = acc;

          if (
            discount &&
            moment(
              moment(discount.valid_to).format("YYYY-MM-DD HH:mm")
            ).isSameOrAfter(
              moment(bookingData.start_time).format("YYYY-MM-DD HH:mm")
            ) &&
            moment(
              moment(discount.valid_from).format("YYYY-MM-DD HH:mm")
            ).isSameOrBefore(
              moment(bookingData.start_time).format("YYYY-MM-DD HH:mm")
            )
          ) {
            if (discount.type === "Percentage") {
              amount += (+discount.value * +curr.price) / 100;
            } else {
              amount += +discount.value;
            }
          }
          return amount;
        }, 0);
    }

    return (
      <div className="booking-success fade-out">
        <div className="booking-success__wrapper">
          <p className="booking-success__username">
            <strong style={{ fontWeight: "500" }}>
              {this.props.user.userInfo.username}
            </strong>
            さん
          </p>

          <div className="booking-success__content booking-content">
            <div className="booking-content__ttl">
              {shopDetail.booking_confirm ? (
                <>
                  <h2>リクエスト予約を受け付けました。 </h2>
                  <pre>
                    {`予約確定した場合
履歴の予約待ちが 予約確定に変更されます。

サブコレアプリから予約された方はプッシュ通知で予約確定が通知されます。
アプリの予約一覧からも予約履歴をご確認頂けます。`}
                  </pre>
                </>
              ) : (
                <>
                  <h2>予約完了</h2>
                  <p>以下のオプションで予約を完了しました。</p>
                </>
              )}
            </div>

            <div className="booking-content__row menu">
              <div className="booking-content__label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.042"
                  height="19.011"
                  viewBox="0 0 20.042 19.011"
                >
                  <g
                    id="Group_41"
                    data-name="Group 41"
                    transform="translate(-447.781 -3267.811)"
                  >
                    <path
                      id="Path_155"
                      data-name="Path 155"
                      d="M464.618,3267.856h0Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_156"
                      data-name="Path 156"
                      d="M467.692,3271.071a.449.449,0,0,0-.315-.131,11.074,11.074,0,0,0-1.967.2c0-.916,0-2.679,0-2.763a.57.57,0,0,0-.055-.244.559.559,0,0,0-.111-.157.588.588,0,0,0-.207-.127.621.621,0,0,0-.2-.033.587.587,0,0,0-.226.045,11.5,11.5,0,0,0-2.288,1.348,14.849,14.849,0,0,0-2.986,2.953,9.468,9.468,0,0,0-1,1.647,5.392,5.392,0,0,0-.411,1.23h-.269a6.078,6.078,0,0,0-.692-1.781,12.974,12.974,0,0,0-2.607-3.154,12.8,12.8,0,0,0-3.383-2.243.579.579,0,0,0-.225-.045.581.581,0,0,0-.312.088.559.559,0,0,0-.188.2.549.549,0,0,0-.068.274c0,.084,0,1.847,0,2.763a11.074,11.074,0,0,0-1.967-.2.447.447,0,0,0-.445.445v10.967a.442.442,0,0,0,.383.44,13.863,13.863,0,0,1,1.985.456,16.768,16.768,0,0,1,3.278,1.383,10.67,10.67,0,0,1,1.424.949,6.181,6.181,0,0,1,1.072,1.066.445.445,0,0,0,.354.176h3.049a.446.446,0,0,0,.354-.176,6.885,6.885,0,0,1,1.514-1.4,14.519,14.519,0,0,1,3.159-1.625,15.291,15.291,0,0,1,3.086-.831.443.443,0,0,0,.384-.44v-10.967A.447.447,0,0,0,467.692,3271.071Zm-11.727,14.3a9.012,9.012,0,0,0-1.044-.851,15.256,15.256,0,0,0-3.356-1.729,16.631,16.631,0,0,0-2.894-.818v-10.127a12.753,12.753,0,0,1,1.877.267.524.524,0,0,0,.1.01l-.005.017a9.752,9.752,0,0,1,3.391,1.373,5.661,5.661,0,0,1,1.935,2.051v9.807Zm-1.589-12.376a10.208,10.208,0,0,0-3.292-1.394v-.425c0-.618,0-1.666,0-2.291a11.369,11.369,0,0,1,1.658,1.035,13.927,13.927,0,0,1,2.8,2.771,8.45,8.45,0,0,1,.9,1.486,4.465,4.465,0,0,1,.362,1.123h-.276A6.273,6.273,0,0,0,454.376,3272.994Zm4.637,12.936h-2.424v-10.006h2.424Zm.058-10.631h-.278a5.039,5.039,0,0,1,.613-1.61,12.132,12.132,0,0,1,2.427-2.927,12.635,12.635,0,0,1,2.689-1.878c0,.321,0,.752,0,1.181,0,.665,0,1.32,0,1.535a10.221,10.221,0,0,0-3.291,1.394A6.261,6.261,0,0,0,459.071,3275.3Zm7.86,6.674a15.23,15.23,0,0,0-1.736.422,17.6,17.6,0,0,0-3.457,1.459,11.956,11.956,0,0,0-1.543,1.028c-.2.157-.381.32-.557.488v-9.807a5.664,5.664,0,0,1,1.936-2.051,9.733,9.733,0,0,1,3.389-1.373l0-.017a.5.5,0,0,0,.095-.01,12.792,12.792,0,0,1,1.877-.267v10.128Z"
                      fill="#231815"
                    />
                  </g>
                </svg>
                <span>メニュー</span>
              </div>
              <div className="booking-content__content">
                <ul>
                  {!isEmpty(bookingData)
                    ? bookingData.menu.map((menu) => (
                        <li key={menu.id}>{menu.name}</li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="booking-content__row">
              <div className="booking-content__label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.468"
                  height="20.435"
                  viewBox="0 0 18.468 20.435"
                >
                  <g
                    id="Group_42"
                    data-name="Group 42"
                    transform="translate(-446.286 -3313.007)"
                  >
                    <path
                      id="Path_157"
                      data-name="Path 157"
                      d="M460.557,3319.047a5.04,5.04,0,1,0-5.039,5.038A5.038,5.038,0,0,0,460.557,3319.047Z"
                      fill="#fff"
                      stroke="#231815"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_158"
                      data-name="Path 158"
                      d="M455.518,3326.785a8.351,8.351,0,0,0-7.816,5.657h15.634A8.356,8.356,0,0,0,455.518,3326.785Z"
                      fill="#fff"
                      stroke="#231815"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                  </g>
                </svg>

                <span>
                  {window.store.getState().user.shopDetail.section_name_1}
                </span>
              </div>
              <div className="booking-content__content">
                {!isEmpty(bookingData) ? <p>{bookingData.staff.name}</p> : ""}
              </div>
            </div>
            <div className="booking-content__row">
              <div className="booking-content__label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.875"
                  height="19.188"
                  viewBox="0 0 18.875 19.188"
                >
                  <g
                    id="Group_43"
                    data-name="Group 43"
                    transform="translate(-445.919 -3357.993)"
                  >
                    <path
                      id="Path_159"
                      data-name="Path 159"
                      d="M451.943,3361.358a.942.942,0,0,0,.941-.942v-1.482a.941.941,0,1,0-1.882,0v1.482A.941.941,0,0,0,451.943,3361.358Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_160"
                      data-name="Path 160"
                      d="M458.886,3361.358a.942.942,0,0,0,.942-.942v-1.482a.941.941,0,0,0-1.883,0v1.482A.942.942,0,0,0,458.886,3361.358Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_161"
                      data-name="Path 161"
                      d="M462.15,3359.335h-1.494v1.081a1.769,1.769,0,1,1-3.538,0v-1.081h-3.406v1.081a1.77,1.77,0,1,1-3.539,0v-1.081h-1.609a2.645,2.645,0,0,0-2.645,2.646v12.555a2.644,2.644,0,0,0,2.645,2.645H462.15a2.643,2.643,0,0,0,2.644-2.645v-12.555A2.644,2.644,0,0,0,462.15,3359.335Zm1.357,15.2a1.359,1.359,0,0,1-1.357,1.358H448.564a1.359,1.359,0,0,1-1.357-1.358v-10.884h16.3Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_162"
                      data-name="Path 162"
                      d="M453.51,3374.486a.966.966,0,1,0-.966-.965A.966.966,0,0,0,453.51,3374.486Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_163"
                      data-name="Path 163"
                      d="M453.51,3370.792a.965.965,0,1,0-.966-.965A.965.965,0,0,0,453.51,3370.792Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_164"
                      data-name="Path 164"
                      d="M449.818,3374.486a.966.966,0,1,0-.967-.965A.966.966,0,0,0,449.818,3374.486Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_165"
                      data-name="Path 165"
                      d="M449.818,3370.792a.965.965,0,1,0-.967-.965A.965.965,0,0,0,449.818,3370.792Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_166"
                      data-name="Path 166"
                      d="M460.9,3367.1a.964.964,0,1,0-.965-.964A.965.965,0,0,0,460.9,3367.1Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_167"
                      data-name="Path 167"
                      d="M457.2,3367.1a.964.964,0,1,0-.965-.964A.964.964,0,0,0,457.2,3367.1Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_168"
                      data-name="Path 168"
                      d="M457.2,3370.792a.965.965,0,1,0-.965-.965A.964.964,0,0,0,457.2,3370.792Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_169"
                      data-name="Path 169"
                      d="M460.9,3370.792a.965.965,0,1,0-.965-.965A.966.966,0,0,0,460.9,3370.792Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_170"
                      data-name="Path 170"
                      d="M457.2,3374.486a.966.966,0,1,0-.965-.965A.966.966,0,0,0,457.2,3374.486Z"
                      fill="#231815"
                    />
                    <path
                      id="Path_171"
                      data-name="Path 171"
                      d="M453.51,3367.1a.964.964,0,1,0-.966-.964A.965.965,0,0,0,453.51,3367.1Z"
                      fill="#231815"
                    />
                  </g>
                </svg>
                <span>日時</span>
              </div>
              <div className="booking-content__content">
                {!isEmpty(bookingData) ? (
                  <p>
                    {moment(bookingData.start_time).format(
                      "YYYY年 MM月 DD日 HH:mm"
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="booking-content__row">
              <div className="booking-content__label">
                <span className="estimate-amount">見積金額</span>
              </div>
              <div className="booking-content__content">
                {!isEmpty(bookingData) ? (
                  <p>
                    ¥
                    {utils.formatMoney(
                      Number(totalMoney) - Number(totalDiscount),
                      0,
                      3,
                      ",",
                      "."
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="booking-success__buttons">
            <button type="button" onClick={goToBookingHistory}>
              予約履歴へ
            </button>
          </div>
        </div>

        {isShowGuideModal ? (
          <GuideModal
            onClose={() => {
              this.setState({ isShowGuideModal: false });
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSuccess);
