import React, { Component } from "react";
import "./style.scss";

class Loader extends Component {
  render() {
    return (
      <div id="redirect-loader">
        <div id="redirect-loader__shadow"></div>
        <div id="redirect-loader__box"></div>
      </div>
    );
  }
}

export default Loader;
