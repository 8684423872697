import Joi from "joi";
import CONST from "./constant";

export default Joi.object({
  username: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = CONST.usernameRequired;
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  phone_number: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = CONST.phoneRequired;
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  email: Joi.string()
    .allow("", null)
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "メールアドレスを入力してください。";
            break;
          case "string.pattern.base":
            err.message = "メールフォーマットが正しくありません。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  menu: Joi.array()
    .min(1)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "array.min":
            err.message = CONST.menuRequired;
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  staff: Joi.number()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "number.required":
            err.message = CONST.staffRequired;
            break;
          case "number.base":
            err.message = CONST.staffRequired;
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });
