import Joi from "joi";

const schemaNoUrl = Joi.object({
  name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "メニュー名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  price: Joi.number()
    .unsafe()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "number.base":
            err.message = "価格を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  detail: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "説明内容を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

const schemaWithUrl = Joi.object({
  name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "メニュー名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  price: Joi.number()
    .unsafe()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "number.base":
            err.message = "価格を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  image: Joi.string()
    .regex(
      //eslint-disable-next-line
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    )
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.pattern.base":
            err.message = "このURLはYoutubeのURLではありません。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  detail: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "説明内容を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

const schemaValidateLinkYoutube = Joi.object({
  image: Joi.string()
    .allow("")
    .regex(
      //eslint-disable-next-line
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    )
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.pattern.base":
            err.message = "このURLはYoutubeのURLではありません。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

export { schemaNoUrl, schemaWithUrl, schemaValidateLinkYoutube };
