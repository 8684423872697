import React from "react";
import { connect } from "react-redux";
import * as actions from "./../../modules/booking/actions";
import { bindActionCreators } from "redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
// import Bell from "./bell/index.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "./styles.scss";
import CreateDialog from "./dialog/create/index";
import DetailDialog from "./dialog/detail/index";
import Dialog from "../shared/dialog";
import isEmpty from "lodash/isEmpty";
import MyButton from "./../shared/buttons/primary";
import AlertDialog from "./dialog/create-alert";
import cloneDeep from "lodash/cloneDeep";
require("moment-timezone");

const DragAndDropCalendar = withDragAndDrop(Calendar);

const formats = {
  timeGutterFormat: "HH:mm",
};

const TODAY = moment(moment().toDate()).format("YYYY-MM-DD");

class Booking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDay: moment().toDate(),
      workingHours: "",
      toggle: 0,
      hidden: true,
      events: [],
      resourceMap: [],
      localizer: momentLocalizer(moment),
      isCreateModalOpen: false,
      isDeleteModalOpen: false,
      isAlertModalOpen: false,
      bookingData: {},
      listBooking: [],
      deleteBookingId: "",
      defaultTime: {
        start: "",
        end: "",
      },
      detailData: {},
      isDetailModalOpen: false,
      showModal: true,
      blockTimes: [],
      currDayWorkingHoursRaw: [],
      chosenStartDate: "",
    };

    const currDayWorkingHours = this.getCurrentDayWorkingHour();
    this.state.workingHours = this.getWorkingHoursOfDay(currDayWorkingHours);
    this.state.blockTimes = this.calculateTimeBlocks(currDayWorkingHours);
    this.state.currDayWorkingHoursRaw =
      this.calculateCurrDayWorkingHoursRaw(currDayWorkingHours);
  }

  getCurrentDayWorkingHour = () => {
    const WORKING_HOURS = window.store.getState().auth.user.workingHoursOfWeek;
    const currentWeekOfDay = moment(this.state.currentDay)
      .locale("en")
      .format("ddd")
      .toUpperCase();
    const previousDay = moment(this.state.currentDay)
      .add(-1, "day")
      .locale("en")
      .format("ddd")
      .toUpperCase();
    let currDayWorkingHours = WORKING_HOURS.filter(
      (item) => item.dayOfWeek === currentWeekOfDay
    ).map((item) => item.workingHours);

    let prevDayWorkingHours = WORKING_HOURS.filter(
      (item) => item.dayOfWeek === previousDay
    ).map((item) => item.workingHours);

    if (currDayWorkingHours.length) {
      currDayWorkingHours = currDayWorkingHours.reduce((acc, curr) => {
        acc.push(...curr);
        return acc;
      }, []);

      currDayWorkingHours = currDayWorkingHours.filter(
        (item) => item.start < "2400"
      );

      currDayWorkingHours = currDayWorkingHours.map((item) => {
        const hour = cloneDeep(item);

        if (hour.end >= "2400") {
          hour.end = "2359";
        }

        return hour;
      });
    }

    if (prevDayWorkingHours.length) {
      prevDayWorkingHours = prevDayWorkingHours.reduce((acc, curr) => {
        acc.push(...curr);
        return acc;
      }, []);

      prevDayWorkingHours = prevDayWorkingHours
        .filter((item) => item.end > "2400")
        .map((item) => {
          const hour = cloneDeep(item);
          if (hour.start <= "2400") {
            hour.start = "0000";
          } else {
            const startHour = (+hour.start.substr(0, 2) - 24).toString();
            hour.start =
              startHour.length === 1
                ? `0${startHour}${hour.start.substr(2)}`
                : `${startHour}${hour.start.substr(2)}`;
          }

          const endHour = (+hour.end.substr(0, 2) - 24).toString();
          hour.end =
            endHour.length === 1
              ? `0${endHour}${hour.end.substr(2)}`
              : `${endHour}${hour.end.substr(2)}`;

          return hour;
        });
    }

    currDayWorkingHours = [...currDayWorkingHours, ...prevDayWorkingHours];
    currDayWorkingHours.sort((a, b) => {
      if (a.start > b.start) {
        return 1;
      } else {
        return -1;
      }
    });

    currDayWorkingHours = currDayWorkingHours.map((item) => {
      const time = cloneDeep(item);

      time.start = `${moment(this.state.currentDay)
        .set({
          hours: time.start.substr(0, 2),
          minutes: time.start.substr(2),
        })
        .format("YYYY-MM-DDTHH:mmZ")}`;
      time.end = moment(this.state.currentDay)
        .set({
          hours: time.end.substr(0, 2),
          minutes: time.end.substr(2),
        })
        .format("YYYY-MM-DDTHH:mmZ");

      return time;
    });

    return currDayWorkingHours;
  };

  calculateCurrDayWorkingHoursRaw = (workingHour) => {
    return workingHour;
  };

  calculateTimeBlocks = (workingHour) => {
    const blocks = [];

    workingHour.forEach((item) => {
      blocks.push(...this.getBlockTimes(item.start, item.end));
    });

    return blocks;
  };

  fromTimeStrToDatetime(timeStr, date) {
    let time = timeStr.split("");
    time.splice(2, 0, ":");
    time = time.join("");
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];

    return moment(date)
      .set({ hour, minute, second: "00" })
      .format("YYYY-MM-DD HH:mm");
  }

  getBlockTimes(start, end) {
    const block = [];
    let startTime = start;
    while (moment(startTime).isBefore(moment(end))) {
      block.push(moment(startTime).format("HH:mm"));
      startTime = moment(startTime).add(30, "minutes");
    }

    return block;
  }

  getWorkingHoursOfDay = (workingHour) => {
    if (!workingHour.length) {
      return {
        start: moment(this.state.currentDay).startOf("day").toDate(),
        end: moment(this.state.currentDay).endOf("day").toDate(),
        isWorkingDay: false,
      };
    }

    const workingHourMapped = cloneDeep(workingHour);
    let returnWorkingHour = { start: "", end: "", isWorkingDay: true };

    workingHourMapped.forEach((item) => {
      if (!returnWorkingHour.start) {
        returnWorkingHour.start = item.start;
      }
      if (!returnWorkingHour.end) {
        returnWorkingHour.end = item.end;
      }

      if (moment(returnWorkingHour.start).isAfter(item.start)) {
        returnWorkingHour.start = item.start;
      }

      if (moment(returnWorkingHour.end).isBefore(item.end)) {
        returnWorkingHour.end = item.end;
      }
    });

    return returnWorkingHour;
  };

  hideCalendar = (node) => {
    if (node.style.visibility !== "hidden") {
      node.style.visibility = "hidden";
      node.style.opacity = "0";
    }
  };
  showCalendar = (node) => {
    if (node.style.visibility !== "visible") {
      node.style.visibility = "visible";
      node.style.opacity = "1";
    }
  };

  slotPropGetter = (date) => {
    const node = document.querySelector(".rbc-time-view");
    let backgroundColor, borderTop, cursor;

    if (!this.state.workingHours.isWorkingDay) {
      if (node) {
        this.hideCalendar(node);
      }
    } else {
      if (node) {
        this.showCalendar(node);
      }

      if (!this.state.blockTimes.includes(moment(date).format("HH:mm"))) {
        backgroundColor = "#cdcdcd";
        borderTop = "0";
      }
    }

    const style = {
      backgroundColor,
      borderTop,
      cursor,
    };
    return {
      style: style,
    };
  };

  async fetchBooks(day) {
    const result = await window.axios({
      methods: "get",
      url: `shop/${this.props.auth.user.id}/book/?date=${day || TODAY}`,
      // url: `shop/${this.props.auth.user.id}/book/?date=${
      //   day || TODAY
      // }&tz=${moment.tz.guess()}`,
    });

    const listBooking = result.data
      .filter((item) => item.status !== "cancelled")
      .map((item) => ({
        ...item,
        title: `${item.username || item.user.full_name || ""} - ${
          item.phone_number || item.user.phone_number || ""
        }`,
        start: moment(item.start_time).toDate(),
        end: moment(item.end_time).toDate(),
        resourceId: item.staff,
      }));

    this.setState({ listBooking });
  }
  handleToggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  async componentDidMount() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.props.dispatchActions.setIsMobile(isMobile);
    this.fetchBooks();
  }

  drawPlusIcon = (node) => {
    this.drawPlusIcon = function () {};
    node.addEventListener("click", (e) => {
      e.preventDefault();
      if (this.state.toggle === 0) {
        this.setState({ toggle: 1 });
      } else {
        this.setState({ toggle: 0 });
      }
    });
    node.classList.add("plus-icon");
  };

  moveEvent = ({
    event,
    start,
    end,
    resourceId,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    // Check if the event is moved to non-working hours
    if (!this.state.blockTimes.includes(moment(start).format("HH:mm"))) {
      this.setState({ isAlertModalOpen: true });

      return;
    }

    const newEvent = window._.cloneDeep(event);
    newEvent.start = start;
    newEvent.end = end;

    this.setState((prev) => ({
      ...prev,
      listBooking: prev.listBooking.map((item) => {
        if (item.id === newEvent.id) {
          item.start_time = newEvent.start;
          item.end_time = newEvent.end;
          item.start = newEvent.start;
          item.end = newEvent.end;
        }
        return item;
      }),
    }));

    window.axios({
      url: `booking/${event.id}/`,
      method: "patch",
      data: {
        id: newEvent.id,
        end_time: moment(newEvent.end).format("YYYY-MM-DD HH:mm"),
        start_time: moment(newEvent.start).format("YYYY-MM-DD HH:mm"),
      },
    });
  };

  selectEvent = (data) => {
    this.setState({ isDetailModalOpen: true, bookingData: data });
  };

  onCreateEvent = (data) => {
    const { start, end, resourceId } = data;

    if (!this.state.blockTimes.includes(moment(start).format("HH:mm"))) {
      this.setState({
        chosenStartDate: moment(start).format("HH:mm"),
        isAlertModalOpen: true,
      });
      return;
    }

    const bookingData = {
      start_time: start,
      end_time: end,
      shop: this.props.auth.user.id,
      listStaff: this.props.setting.listStaff,
      listMenu: this.props.setting.listMenu,
      staff: resourceId,
    };
    this.setState({ isCreateModalOpen: true, bookingData: bookingData });
  };

  resizeEvent = ({ end, start, event, resourceId }) => {
    // const { events } = this.state;
    // const nextEvents = events.map((existingEvent) => {
    //   return existingEvent.id == event.id
    //     ? { ...existingEvent, start, end }
    //     : existingEvent;
    // });
    // this.setState({
    //   events: nextEvents,
    // });
  };
  onCalendarNavigate = async (date) => {
    this.props.dispatchActions.setSimpleCalendarChosenDate(date);
    await this.setState({ currentDay: date });

    const currDayWorkingHours = this.getCurrentDayWorkingHour();
    await this.setState({
      workingHours: this.getWorkingHoursOfDay(currDayWorkingHours),
      blockTimes: this.calculateTimeBlocks(currDayWorkingHours),
      currDayWorkingHoursRaw:
        this.calculateCurrDayWorkingHoursRaw(currDayWorkingHours),
    });

    this.fetchBooks(moment(date).format("YYYY-MM-DD"));
  };

  closeCreateModal = (data) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isCreateModalOpen: false,
        bookingData: {},
      };
    });
    if (!isEmpty(data) && data.status !== "cancelled") {
      const { setting } = this.props;
      const bookingData = {
        ...data,
        title: `${data.username || data.user.full_name || ""} - ${
          data.phone_number || data.user.phone_number || ""
        }`,
        start: moment(data.start_time).toDate(),
        end: moment(data.end_time).toDate(),
        menu: setting.listMenu.filter((item) => data.menu.includes(item.id)),
        resourceId: data.staff,
      };

      this.setState((prevState) => {
        return {
          ...prevState,
          listBooking: [...prevState.listBooking, bookingData],
        };
      });
    }
  };

  closeDetailModal = (data) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isDetailModalOpen: false,
        bookingData: {},
      };
    });
    if (!isEmpty(data)) {
      const { setting } = this.props;
      if (data.status !== "cancelled") {
        const bookingData = {
          ...data,
          start: moment(data.start_time).toDate(),
          end: moment(data.end_time).toDate(),
          title: `${data.username || data.user.full_name || ""} - ${
            data.phone_number || data.user.phone_number || ""
          }`,
          menu: setting.listMenu.filter((item) => data.menu.includes(item.id)),
          resourceId: data.staff,
        };

        this.setState((prevState) => {
          return {
            ...prevState,
            listBooking: prevState.listBooking.map((item) => {
              if (item.id === bookingData.id) {
                return bookingData;
              }
              return item;
            }),
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            listBooking: prevState.listBooking.filter(
              (item) => item.id !== data.id
            ),
          };
        });
      }
    }
  };

  onDeleteBooking = (data) => {
    this.setState({ isDeleteModalOpen: true, deleteBookingId: data.id });
  };

  closeDeleteModal = async (type) => {
    if (type === 1) {
      await window
        .axios({
          url: `booking/${this.state.deleteBookingId}/`,
          method: "delete",
        })
        .then((result) => {
          if (result.status === 204) {
            const listBooking = this.state.listBooking.filter(
              (item) => item.id !== this.state.deleteBookingId
            );
            this.setState({ listBooking, deleteBookingId: "" });
          }
        });
    }
    this.setState({ isDeleteModalOpen: false });
  };

  render() {
    const {
      localizer,
      isCreateModalOpen,
      bookingData,
      isDetailModalOpen,
      toggle,
      listBooking,
      workingHours,
      currentDay,
      isDeleteModalOpen,
      isAlertModalOpen,
      currDayWorkingHoursRaw,
      chosenStartDate,
    } = this.state;

    const { setting: settingState } = this.props;

    const { closeCreateModal, closeDetailModal, closeDeleteModal } = this;

    return (
      <div id="booking" className="booking">
        <div className="booking__warning">
          {this.props.booking.isMobile &&
          !this.props.booking.warningHasShown ? (
            <div className="booking__warning-content">
              <p>
                予約カレンダーはモバイルプラットフォームでは完全にはサポートされていません。最高のエクスペリエンスを得るにはコンピューターをご利用ください。
              </p>
              <div className="booking__warning-button">
                <MyButton
                  myVariant="create"
                  onClick={() => {
                    this.props.dispatchActions.setWarningShowState(true);
                  }}
                >
                  閉じる
                </MyButton>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="booking__big-calendar">
          {!settingState.listStaff.length ? (
            <h2 className="booking__no-staff-announce">
              {window.store.getState().auth.user.section_name_1}が居ません!
            </h2>
          ) : !settingState.listMenu.length ? (
            <h2 className="booking__no-staff-announce">
              メニューが有りません!
            </h2>
          ) : (
            <DragAndDropCalendar
              id="booking-calendar"
              selectable
              localizer={localizer}
              events={listBooking}
              onEventDrop={this.moveEvent}
              resizable
              onSelectEvent={this.selectEvent}
              onSelectSlot={this.onCreateEvent}
              min={
                toggle === 0
                  ? moment(workingHours.start).toDate()
                  : moment(moment(currentDay).startOf("day")).toDate()
              }
              max={
                toggle === 0
                  ? moment(workingHours.end).toDate()
                  : moment(moment(currentDay).endOf("day")).toDate()
              }
              resources={settingState.listStaff.map((item) => ({
                resourceId: item.id,
                resourceTitle: item.name,
                //  window._.truncate(item.name, {
                //   length: 10,
                //   omission: "...",
                // }),
              }))}
              slotPropGetter={this.slotPropGetter}
              onNavigate={this.onCalendarNavigate}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
              onEventResize={this.resizeEvent}
              defaultView="day"
              views={["day"]}
              step={30}
              showMultiDayTimes={true}
              defaultDate={currentDay}
              formats={formats}
              // longPressThreshold={10}
              components={{
                event: (event) => (
                  <Event {...event} onDelete={this.onDeleteBooking} />
                ),
                toolbar: (props) => (
                  <CustomToolbar {...props} {...this.props} />
                ),
                dateCellWrapper: Book,
              }}
            />
          )}

          {!this.state.workingHours.isWorkingDay &&
          settingState.listStaff.length &&
          settingState.listMenu.length ? (
            <div className="booking__not-woring-day-announce">
              <h2>定休日</h2>
            </div>
          ) : (
            ""
          )}
        </div>

        {isCreateModalOpen && (
          <CreateDialog
            isShow={isCreateModalOpen}
            onClose={closeCreateModal}
            data={bookingData}
            blockTimes={this.state.blockTimes}
            {...this.props}
          />
        )}
        {isDetailModalOpen && (
          <DetailDialog
            onClose={closeDetailModal}
            data={bookingData}
            blockTimes={this.state.blockTimes}
            {...this.props}
          />
        )}
        {isAlertModalOpen ? (
          <AlertDialog
            chosenStartDate={chosenStartDate}
            currDayWorkingHours={currDayWorkingHoursRaw}
            onClose={() => this.setState({ isAlertModalOpen: false })}
          />
        ) : (
          ""
        )}
        {isDeleteModalOpen && (
          <Dialog
            title={"削除"}
            isShow={isDeleteModalOpen}
            onCloseRequest={() => closeDeleteModal(0)}
            closeOnOverlayClick={true}
            className={"custom-delete-dialog"}
          >
            <span
              type="button"
              className="detail-button me-3"
              onClick={() => closeDeleteModal(0)}
            >
              キャンセル
            </span>
            <span
              type="button"
              className="delete-button"
              onClick={() => closeDeleteModal(1)}
            >
              削除
            </span>
          </Dialog>
        )}
      </div>
    );
  }
}

function Event(props) {
  // const onDeleteClick = (e) => {
  //   e.stopPropagation();
  //   props.onDelete(props.event);
  // };
  return (
    <div className="rbc-m-custom-event">
      {/* <span
        className="material-icons delete-booking-btn"
        onClick={onDeleteClick}
      >
        delete
      </span> */}
      <ul className="rbc-m-custom-event__content">
        <li>ユーザ名 : {props.event.username || props.event.user.full_name}</li>
        <li>
          電話番号 : {props.event.phone_number || props.event.user.phone_number}
        </li>
        {/* <li>
          メニュー : {props.event.menu.map((item) => item.name).join(", ")}
        </li> */}
        <li>
          ステータス : {window.utils.statusMasterData[props.event.status]}
        </li>
      </ul>
    </div>
  );
}

function Book({ event }) {
  return (
    <div className="rbc-day-bg">
      <button>Book Class</button>
    </div>
  );
}

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

class CustomToolbar extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.booking.simpleCalendarChosenDate !==
      prevProps.booking.simpleCalendarChosenDate
    ) {
      this.props.onNavigate(
        navigate.DATE,
        this.props.booking.simpleCalendarChosenDate
      );
    }
  }
  navigate = (action) => {
    this.props.onNavigate(action);
  };
  render() {
    let { label } = this.props;
    return (
      <div className="rbc-toolbar custom">
        <div className="navigate-wrapper">
          <div className="rbc-toolbar-label me-2">{label}</div>
          <div className="rbc-navigate-wrapper">
            <span className="rbc-btn-group custom">
              {/* <span
                type="button"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              > */}
              {/* <i
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                className="fas fa-chevron-left"
              ></i> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
                <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
              </svg>
              {/* </span> */}
            </span>
            <span className="rbc-btn-group custom me-1">
              {/* <span
                type="button"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              > */}
              {/* <i
                onClick={this.navigate.bind(null, navigate.NEXT)}
                className="fas fa-chevron-right"
              ></i> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
                <g>
                  <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
                </g>
              </svg>
              {/* </span> */}
            </span>
            <span
              className="today-btn"
              onClick={this.navigate.bind(null, navigate.TODAY)}
            >
              今日
            </span>
          </div>
        </div>
        {/* <div className="rbc-toolbar-custom__bell">
          <Bell />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking: state.booking,
    auth: state.auth,
    setting: state.setting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
