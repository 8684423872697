import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  getShopMenu,
  getShopStaff,
  getShopDiscount,
  getShopDetail,
} from "./api";
import {
  setShopMenu,
  setShopStaff,
  setShopDiscount,
  setShopDetail,
} from "./actions";
import {
  GET_SHOP_MENU,
  GET_SHOP_STAFF,
  GET_SHOP_DISCOUNT,
  GET_SHOP_DETAIL,
} from "./types";

function* handleGetShopMenu(action) {
  try {
    const result = yield call(getShopMenu, action.payload);
    const menus = result.data;
    yield put(setShopMenu(menus));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* handleGetShopSaff(action) {
  try {
    const result = yield call(getShopStaff, action.payload);
    const menus = result.data;
    yield put(setShopStaff(menus));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* handleGetShopDiscount(action) {
  try {
    const result = yield call(getShopDiscount, action.payload);
    const discounts = result.data;
    yield put(setShopDiscount(discounts));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* handleGetShopDetail(action) {
  try {
    const result = yield call(getShopDetail, action.payload);
    const shopDetail = result.data;
    yield put(setShopDetail(shopDetail));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* appSaga() {
  yield all([
    takeLatest(GET_SHOP_MENU, handleGetShopMenu),
    takeLatest(GET_SHOP_STAFF, handleGetShopSaff),
    takeLatest(GET_SHOP_DISCOUNT, handleGetShopDiscount),
    takeLatest(GET_SHOP_DETAIL, handleGetShopDetail),
  ]);
}

export default appSaga;
