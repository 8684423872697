import React, { Component, useEffect, useState } from "react";
import baseContentLayout from "../../shared/base-content/index";
import "./index.scss";
import CreateDialog from "./dialog/create";
import EditDialog from "./dialog/edit";
import DeleteDialog from "./dialog/delete";
import moment from "moment";
import MyButton from "./../../shared/buttons/primary";

const Content = () => {
  // const globalState = window.store.getState();
  const [createDialogShow, setCreateDialogShow] = useState(false);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [deleteDialogShow, setDeleteDialogShow] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [listDiscount, setListDiscount] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchListDiscount();
  }, []);

  function fetchListDiscount() {
    window
      .axios({
        url: `shop/${window.store.getState().auth.user.id}/discount/`,
        method: "get",
      })
      .then((result) => {
        setListDiscount(result.data);
        setLoading(false);
      });
  }

  async function editBtnClick(data) {
    await setDialogData(data);
    setEditDialogShow(true);
  }

  async function deleteBtnClick(data) {
    await setDialogData(data);
    setDeleteDialogShow(true);
  }

  function onCloseEditDialog(data) {
    setEditDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      const listDiscountMap = listDiscount.map((item) => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      });
      setListDiscount(listDiscountMap);
    }
  }

  function onCloseCreateDialog(data) {
    setCreateDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      setListDiscount((prev) => [...prev, data]);
    }
  }

  function onCloseDeleteDialog(data) {
    setDeleteDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      const listDiscountMap = listDiscount.filter(
        (item) => item.id !== data.id
      );
      setListDiscount(listDiscountMap);
    }
  }

  async function createBtnClick() {
    await setDialogData({});
    setCreateDialogShow(true);
  }

  return (
    <div className="menu">
      <div className="menu__search mb-2">
        {/* <span type="button" className="add-button" onClick={createBtnClick}>
          追加
        </span> */}
        <MyButton myVariant="add" onClick={createBtnClick}>
          追加
        </MyButton>
      </div>
      {!isLoading ? (
        <div className="history__table discount__table">
          <table className="table">
            <thead className="">
              <tr>
                <th scope="col" className="col-username">
                  割引名
                </th>
                <th scope="col" className="col-service">
                  割引価格
                </th>
                {/* <th scope="col" className="col-time">
                  割引番号
                </th> */}
                <th scope="col" className="col-status">
                  時間
                </th>
                <th scope="col" className="col-activity">
                  操作
                </th>
              </tr>
            </thead>
            <tbody>
              {listDiscount.length ? (
                listDiscount.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {window._.truncate(item.name, {
                        length: 30,
                        omission: "...",
                      })}
                    </td>
                    <td>
                      {item.value.split(".")[0]}{" "}
                      {item.type === "Fixed" ? "円" : "%"}
                    </td>
                    {/* <td>{item.code}</td> */}
                    <td>
                      {moment(item.valid_from).format("YYYY年MM月DD日 hh:mm a")}{" "}
                      ~ {moment(item.valid_to).format("YYYY年MM月DD日 hh:mm a")}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <MyButton
                        className="me-2"
                        myVariant="edit"
                        onClick={() => editBtnClick(item)}
                      >
                        編集
                      </MyButton>
                      <MyButton
                        myVariant="delete"
                        onClick={() => deleteBtnClick(item)}
                      >
                        削除
                      </MyButton>
                      {/* <span
                        type="button"
                        className="edit-button me-2"
                        onClick={() => editBtnClick(item)}
                      >
                        編集
                      </span> */}
                      {/* <span
                        type="button"
                        className="delete-button me-2"
                        onClick={() => deleteBtnClick(item)}
                      >
                        削除
                      </span> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">割引が有りません</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader">Loading...</div>
        </div>
      )}
      {createDialogShow && (
        <CreateDialog
          type={0}
          isShow={createDialogShow}
          onCloseRequest={onCloseCreateDialog}
          data={dialogData}
        ></CreateDialog>
      )}
      {editDialogShow && (
        <EditDialog
          type={0}
          isShow={editDialogShow}
          onCloseRequest={onCloseEditDialog}
          data={dialogData}
        ></EditDialog>
      )}
      {deleteDialogShow && (
        <DeleteDialog
          type={0}
          isShow={deleteDialogShow}
          onCloseRequest={onCloseDeleteDialog}
          data={dialogData}
        ></DeleteDialog>
      )}
    </div>
  );
};
const MenuContent = baseContentLayout(Content);

class index extends Component {
  render() {
    return <MenuContent name="割引設定" />;
  }
}

export default index;
