import React, { Component, useEffect, useState } from "react";
import baseContentLayout from "./../../shared/base-content/index";
import "./index.scss";
import CreateDialog from "./dialog/create";
import EditDialog from "./dialog/edit";
import DeleteDialog from "./dialog/delete";
import MyButton from "./../../shared/buttons/primary";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

import * as actions from "./../../../modules/setting/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Content = (props) => {
  const globalState = window.store.getState();
  const [listStaff, setListStaff] = useState([]);
  const [dialogShow, setDialogShow] = useState(false);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [deleteDialogShow, setDeleteDialogShow] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [masterWorkingHoursStart, setMasterWorkingHoursStart] = useState({});
  const [masterWorkingHoursEnd, setMasterWorkingHoursEnd] = useState({});

  useEffect(() => {
    const WORKING_HOURS = window.store.getState().auth.user.workingHoursOfWeek;
    let optimizeWH = WORKING_HOURS.reduce((acc, curr) => {
      let pushIndex = -1;
      acc.forEach((element, index) => {
        const existItemIndex = element.findIndex(
          (item) => item.dayOfWeek === curr.dayOfWeek
        );
        if (existItemIndex !== -1) {
          pushIndex = index;
        }
      });

      if (pushIndex !== -1) {
        acc[pushIndex].push(curr);
      } else {
        acc.push([curr]);
      }
      return acc;
    }, []);

    const dayOfWeekArr = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const masterWorkingHoursStart = {};
    const masterWorkingHoursEnd = {};

    optimizeWH.forEach((wHour) => {
      let clone = cloneDeep(wHour);
      clone = clone
        .map((item) => item.workingHours)
        .reduce((acc, curr) => {
          acc.push(...curr);

          return acc;
        }, []);

      const blockStart = [];
      const blockEnd = [];

      clone.forEach((item) => {
        blockStart.push(
          getBlockTimes(
            fromTimeStrToDatetime(item.start),
            fromTimeStrToDatetime(item.end)
          )
        );
        blockEnd.push(
          getBlockTimes(
            fromTimeStrToDatetime(item.start),
            fromTimeStrToDatetime(item.end),
            true
          )
        );
      });

      const sort = (a, b) => {
        if (a[0] > b[0]) {
          return 1;
        } else {
          return -1;
        }
      };
      blockStart.sort(sort);
      blockEnd.sort(sort);

      masterWorkingHoursStart[dayOfWeekArr.indexOf(wHour[0].dayOfWeek)] =
        blockStart;
      masterWorkingHoursEnd[dayOfWeekArr.indexOf(wHour[0].dayOfWeek)] =
        blockEnd;

      return clone;
    });

    setMasterWorkingHoursStart(masterWorkingHoursStart);
    setMasterWorkingHoursEnd(masterWorkingHoursEnd);
  }, []);

  function fromTimeStrToDatetime(timeStr) {
    let time = timeStr.split("");
    time.splice(2, 0, ":");
    time = time.join("");
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];

    return moment()
      .set({ hour, minute, second: "00" })
      .format("YYYY-MM-DD HH:mm");
  }

  function getBlockTimes(start, end, isFull = false) {
    const block = [];
    let startTime = start;
    const TIME_GAP = 30;

    if (isFull) {
      while (moment(startTime).isSameOrBefore(moment(end))) {
        const isNextDay = moment(startTime).isAfter(start, "day");

        if (isNextDay) {
          let hour = +moment(startTime).format("HH") + 24;
          block.push(`${hour}:${moment(startTime).format("mm")}`);
        } else {
          block.push(moment(startTime).format("HH:mm"));
        }
        startTime = moment(startTime).add(TIME_GAP, "minutes");
      }

      let endTime = "";
      const startTimeDay = moment(start).format("YYYY-MM-DD");
      const endTimeDay = moment(end).format("YYYY-MM-DD");

      if (endTimeDay > startTimeDay) {
        endTime = `${+moment(end).format("HH") + 24}:${moment(end).format(
          "mm"
        )}`;
      } else {
        endTime = moment(end).format("HH:mm");
      }

      if (endTime > block[block.length - 1]) {
        block.push(endTime);
      }
    } else {
      while (moment(startTime).isBefore(moment(end))) {
        const isNextDay = moment(startTime).isAfter(start, "day");

        if (isNextDay) {
          let hour = +moment(startTime).format("HH") + 24;
          block.push(`${hour}:${moment(startTime).format("mm")}`);
        } else {
          block.push(moment(startTime).format("HH:mm"));
        }
        startTime = moment(startTime).add(TIME_GAP, "minutes");
      }
    }

    return block;
  }

  useEffect(() => {
    setListStaff(globalState.setting.listStaff);
  }, [globalState.setting.listStaff]);

  async function editBtnClick(data) {
    await setDialogData(data);
    setEditDialogShow(true);
  }

  async function deleteBtnClick(data) {
    await setDialogData(data);
    setDeleteDialogShow(true);
  }

  function changeStaffStatus(e, item) {
    window
      .axios({
        url: `staff/${item.id}/`,
        method: "patch",
        data: {
          active: e.target.checked,
        },
      })
      .then((result) => {
        const listStaffMap = listStaff.map((item) => {
          if (item.id === result.data.id) {
            return result.data;
          }
          return item;
        });

        setListStaff(listStaffMap);
      });
  }

  function reloadStaff() {
    const { dispatchActions } = props;
    dispatchActions.getListStaff(props.auth.user.id);
  }

  function onCloseDeleteDialog(data) {
    setDeleteDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      reloadStaff();
    }
  }

  function onCloseCreateDialog(data) {
    setDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      reloadStaff();
    }
  }
  function onCloseEditDialog(data) {
    setEditDialogShow(false);

    if (!window.utils.isEmptyObject(data)) {
      reloadStaff();
    }
  }

  async function createBtnClick() {
    await setDialogData({});
    setDialogShow(true);
  }

  return (
    <div className="menu staff-view">
      <div className="menu__search mb-4">
        <MyButton myVariant="add" onClick={createBtnClick}>
          追加
        </MyButton>
      </div>
      <div className="history__table staff-view__table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="col-username">
                {window.store.getState().auth.user.section_name_1}名
              </th>
              <th scope="col" className="col-service">
                説明
              </th>
              <th scope="col" className="col-time">
                ステータス
              </th>
              <th scope="col" className="col-activity">
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {listStaff.length ? (
              listStaff.map((item) => (
                <tr key={item.id}>
                  <td>
                    {window._.truncate(item.name, {
                      length: 30,
                      omission: "...",
                    })}
                  </td>
                  <td>
                    {window._.truncate(item.detail, {
                      length: 30,
                      omission: "...",
                    })}
                  </td>
                  <td>
                    <div className="form-switch">
                      <input
                        checked={item.active}
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => changeStaffStatus(e, item)}
                      />
                    </div>
                  </td>
                  <td className="buttons">
                    <MyButton
                      className="me-2"
                      myVariant="edit"
                      onClick={() => editBtnClick(item)}
                    >
                      編集
                    </MyButton>
                    <MyButton
                      myVariant="delete"
                      onClick={() => deleteBtnClick(item)}
                    >
                      削除
                    </MyButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  {window.store.getState().auth.user.section_name_1}
                  が存在しません
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {dialogShow && (
        <CreateDialog
          type={0}
          isShow={dialogShow}
          onCloseRequest={onCloseCreateDialog}
          masterWorkingHoursStart={masterWorkingHoursStart}
          masterWorkingHoursEnd={masterWorkingHoursEnd}
          data={dialogData}
        ></CreateDialog>
      )}
      {editDialogShow && (
        <EditDialog
          type={0}
          isShow={editDialogShow}
          onCloseRequest={onCloseEditDialog}
          masterWorkingHoursStart={masterWorkingHoursStart}
          masterWorkingHoursEnd={masterWorkingHoursEnd}
          data={dialogData}
        ></EditDialog>
      )}
      {deleteDialogShow && (
        <DeleteDialog
          type={0}
          isShow={deleteDialogShow}
          onCloseRequest={onCloseDeleteDialog}
          data={dialogData}
        ></DeleteDialog>
      )}
    </div>
  );
};
const StaffContent = baseContentLayout(Content);

class index extends Component {
  render() {
    return (
      <StaffContent
        name={`${window.store.getState().auth.user.section_name_1}設定`}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
