import Joi from "joi";

const schemaPercentage = Joi.object({
  name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "割引名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  value: Joi.number()
    .required()
    .max(100)
    .min(1)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "number.base":
            err.message = "割引価格を入力してください。";
            break;
          case "number.max":
            err.message = "0 < 割引価格 <= 100";
            break;
          case "number.min":
            err.message = "0 < 割引価格 <= 100";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  valid_from: Joi.date().iso(),
  valid_to: Joi.date()
    .iso()
    .min(Joi.ref("valid_from"))
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "date.min":
            err.message = "開始日時 < 終了日時";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

const schemaFixed = Joi.object({
  name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "割引名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  value: Joi.number()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "number.base":
            err.message = "割引価格を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  valid_from: Joi.date().iso(),
  valid_to: Joi.date()
    .iso()
    .min(Joi.ref("valid_from"))
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "date.min":
            err.message = "開始日時 < 終了日時";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

const schemaDate = Joi.object({
  valid_from: Joi.date().iso(),
  valid_to: Joi.date()
    .iso()
    .min(Joi.ref("valid_from"))
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "date.min":
            err.message = "開始日時 < 終了日時";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

export { schemaPercentage, schemaFixed, schemaDate };
