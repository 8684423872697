import React, { Component } from "react";
import moment from "moment";
import Modal from "./../../../shared/dialog";
import request from "../../../../core/api";
import utils from "./../../../../core/utils";
import Joi from "joi";
const schema = Joi.object({
  phone: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "電話番号を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false });

class confirmBookingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisabled: false,
      phone: window.store.getState().user.userInfo.phone_number || "",
      phoneError: "",
      note: "",
    };
  }
  createBooking = async () => {
    await this.setState({ isBtnDisabled: true, phoneError: "" });

    const { error } = schema.validate({
      phone: this.state.phone,
    });

    if (error) {
      this.setState({
        phoneError: error.details[0].message,
        isBtnDisabled: false,
      });
      return;
    }

    const { shopDetail } = this.props.user;
    const { chosenMenu, chosenStaff, chosenTime, duration } =
      this.props.user.userBookingData;
    const submitData = {
      menu: chosenMenu.split(",").map((item) => +item),
      status: "waiting",
      staff: chosenStaff,
      start_time: moment(chosenTime).format("YYYY-MM-DD HH:mm"),
      end_time: moment(chosenTime)
        .add(duration, "minutes")
        .format("YYYY-MM-DD HH:mm"),
      shop: shopDetail.id,
      phone_number: this.state.phone,
      note: this.state.note,
      from_user: true,
    };

    request({
      url: `shop/${shopDetail.id}/user_book/`,
      method: "post",
      data: submitData,
    }).then((result) => {
      this.props.onClose(result.data.id);
    });
  };
  render() {
    const { shopMenus, shopStaffs, userBookingData, shopDetail } =
      this.props.user;
    const { createBooking } = this;
    const { isBtnDisabled, phoneError } = this.state;

    return (
      <Modal
        id="confirm-booking-dialog"
        className={"confirm-booking-dialog"}
        title={"予約内容確認"}
        isShow={true}
        closeByEscKey={false}
        showCloseButton={true}
        onCloseRequest={() => this.props.onClose()}
        closeOnOverlayClick={false}
      >
        <p className="confirm-booking-dialog__hint">
          {shopDetail.booking_confirm
            ? "以下の内容でリクエスト予約を受け付けます。"
            : "以下のオプションで予約を完了します。"}
        </p>

        <div className="confirm-booking-dialog__result">
          <div className="confirm-booking-dialog__result-row">
            <div className="confirm-booking-dialog__result-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.042"
                height="19.011"
                viewBox="0 0 20.042 19.011"
              >
                <g
                  id="グループ_40"
                  data-name="グループ 40"
                  transform="translate(-3845.14 -2223.393)"
                >
                  <path
                    id="パス_60"
                    data-name="パス 60"
                    d="M3861.977,2223.438h0Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_61"
                    data-name="パス 61"
                    d="M3865.051,2226.653a.449.449,0,0,0-.315-.131,11.072,11.072,0,0,0-1.967.2c0-.916,0-2.679,0-2.763a.576.576,0,0,0-.055-.245.567.567,0,0,0-.111-.156.578.578,0,0,0-.207-.127.613.613,0,0,0-.195-.033.588.588,0,0,0-.227.045,11.511,11.511,0,0,0-2.288,1.348,14.852,14.852,0,0,0-2.985,2.953,9.3,9.3,0,0,0-1,1.647,5.378,5.378,0,0,0-.412,1.23h-.269a6.091,6.091,0,0,0-.691-1.782,13,13,0,0,0-2.608-3.153,12.788,12.788,0,0,0-3.383-2.243.578.578,0,0,0-.224-.045.558.558,0,0,0-.173.026.529.529,0,0,0-.14.062.557.557,0,0,0-.187.2.542.542,0,0,0-.069.274c0,.084,0,1.847,0,2.763a11.072,11.072,0,0,0-1.967-.2.448.448,0,0,0-.445.445v10.967a.441.441,0,0,0,.383.439,13.979,13.979,0,0,1,1.985.457,16.82,16.82,0,0,1,3.279,1.383,10.654,10.654,0,0,1,1.423.949,6.221,6.221,0,0,1,1.073,1.066.443.443,0,0,0,.353.176h3.049a.447.447,0,0,0,.355-.176,6.852,6.852,0,0,1,1.513-1.4,14.485,14.485,0,0,1,3.159-1.626,15.317,15.317,0,0,1,3.086-.832.441.441,0,0,0,.384-.439v-10.967A.447.447,0,0,0,3865.051,2226.653Zm-11.726,14.3a9.093,9.093,0,0,0-1.044-.851,15.284,15.284,0,0,0-3.357-1.729,16.515,16.515,0,0,0-2.893-.818v-10.127a12.72,12.72,0,0,1,1.877.267.505.505,0,0,0,.095.01l0,.017a9.736,9.736,0,0,1,3.391,1.373,5.679,5.679,0,0,1,1.936,2.051v9.807Zm-1.59-12.376a10.219,10.219,0,0,0-3.291-1.394v-.425c0-.618,0-1.666,0-2.291a11.368,11.368,0,0,1,1.658,1.035,13.879,13.879,0,0,1,2.8,2.771,8.458,8.458,0,0,1,.9,1.486,4.464,4.464,0,0,1,.363,1.123h-.277A6.273,6.273,0,0,0,3851.735,2228.576Zm4.638,12.936h-2.424v-10.006h2.424Zm.057-10.631h-.278a5.063,5.063,0,0,1,.613-1.61,12.141,12.141,0,0,1,2.428-2.927,12.616,12.616,0,0,1,2.688-1.878c0,.321,0,.752,0,1.181,0,.665,0,1.32,0,1.535a10.224,10.224,0,0,0-3.292,1.394A6.262,6.262,0,0,0,3856.43,2230.881Zm7.861,6.674a15.191,15.191,0,0,0-1.737.422,17.654,17.654,0,0,0-3.457,1.459,11.951,11.951,0,0,0-1.543,1.028q-.293.235-.556.488v-9.807a5.659,5.659,0,0,1,1.935-2.051,9.746,9.746,0,0,1,3.39-1.373l0-.017a.514.514,0,0,0,.095-.01,12.757,12.757,0,0,1,1.877-.267v10.128Z"
                    fill="#231815"
                  />
                </g>
              </svg>
              <span>メニュー</span>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <ul>
                {userBookingData.chosenMenu
                  .split(",")
                  .map((item) => shopMenus.find((menu) => menu.id === +item))
                  ?.map((menu) => (
                    <li key={menu.id}>{menu.name}</li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="confirm-booking-dialog__result-row">
            <div className="confirm-booking-dialog__result-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.468"
                height="20.435"
                viewBox="0 0 18.468 20.435"
              >
                <g
                  id="グループ_41"
                  data-name="グループ 41"
                  transform="translate(-3843.644 -2268.589)"
                >
                  <path
                    id="パス_62"
                    data-name="パス 62"
                    d="M3857.917,2274.629a5.04,5.04,0,1,0-5.039,5.038A5.038,5.038,0,0,0,3857.917,2274.629Z"
                    fill="#fff"
                    stroke="#231815"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                  <path
                    id="パス_63"
                    data-name="パス 63"
                    d="M3852.878,2282.367a8.353,8.353,0,0,0-7.817,5.657H3860.7A8.356,8.356,0,0,0,3852.878,2282.367Z"
                    fill="#fff"
                    stroke="#231815"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                </g>
              </svg>
              <span>
                {window.store.getState().user.shopDetail.section_name_1}
              </span>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <p>
                {
                  shopStaffs.find(
                    (item) => item.id === userBookingData.chosenStaff
                  ).name
                }
              </p>
            </div>
          </div>
          <div className="confirm-booking-dialog__result-row">
            <div className="confirm-booking-dialog__result-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.875"
                height="19.188"
                viewBox="0 0 18.875 19.188"
              >
                <g
                  id="グループ_42"
                  data-name="グループ 42"
                  transform="translate(-3843.279 -2313.575)"
                >
                  <path
                    id="パス_64"
                    data-name="パス 64"
                    d="M3849.3,2316.94a.942.942,0,0,0,.942-.942v-1.482a.941.941,0,0,0-1.883,0V2316A.941.941,0,0,0,3849.3,2316.94Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_65"
                    data-name="パス 65"
                    d="M3856.246,2316.94a.942.942,0,0,0,.941-.942v-1.482a.942.942,0,0,0-1.883,0V2316A.942.942,0,0,0,3856.246,2316.94Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_66"
                    data-name="パス 66"
                    d="M3859.509,2314.917h-1.494V2316a1.769,1.769,0,1,1-3.538,0v-1.081h-3.405V2316a1.77,1.77,0,1,1-3.539,0v-1.081h-1.61a2.644,2.644,0,0,0-2.644,2.646v12.555a2.643,2.643,0,0,0,2.644,2.645h13.586a2.644,2.644,0,0,0,2.645-2.645v-12.555A2.645,2.645,0,0,0,3859.509,2314.917Zm1.358,15.2a1.359,1.359,0,0,1-1.358,1.358h-13.586a1.359,1.359,0,0,1-1.357-1.358v-10.884h16.3Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_67"
                    data-name="パス 67"
                    d="M3850.87,2330.068a.966.966,0,1,0-.966-.965A.966.966,0,0,0,3850.87,2330.068Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_68"
                    data-name="パス 68"
                    d="M3850.87,2326.373a.965.965,0,1,0-.966-.964A.964.964,0,0,0,3850.87,2326.373Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_69"
                    data-name="パス 69"
                    d="M3847.177,2330.068a.966.966,0,1,0-.967-.965A.966.966,0,0,0,3847.177,2330.068Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_70"
                    data-name="パス 70"
                    d="M3847.177,2326.373a.965.965,0,1,0-.967-.964A.963.963,0,0,0,3847.177,2326.373Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_71"
                    data-name="パス 71"
                    d="M3858.256,2322.68a.964.964,0,1,0-.964-.964A.965.965,0,0,0,3858.256,2322.68Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_72"
                    data-name="パス 72"
                    d="M3854.563,2322.68a.964.964,0,1,0-.965-.964A.965.965,0,0,0,3854.563,2322.68Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_73"
                    data-name="パス 73"
                    d="M3854.563,2326.373a.965.965,0,1,0-.965-.964A.965.965,0,0,0,3854.563,2326.373Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_74"
                    data-name="パス 74"
                    d="M3858.256,2326.373a.965.965,0,1,0-.964-.964A.964.964,0,0,0,3858.256,2326.373Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_75"
                    data-name="パス 75"
                    d="M3854.563,2330.068a.966.966,0,1,0-.965-.965A.967.967,0,0,0,3854.563,2330.068Z"
                    fill="#231815"
                  />
                  <path
                    id="パス_76"
                    data-name="パス 76"
                    d="M3850.87,2322.68a.964.964,0,1,0-.966-.964A.965.965,0,0,0,3850.87,2322.68Z"
                    fill="#231815"
                  />
                </g>
              </svg>
              <span>日時</span>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <p>
                {moment(userBookingData.chosenTime).format(
                  "YYYY年 MM月 DD日 HH:mm"
                )}
              </p>
            </div>
          </div>
          <div className="confirm-booking-dialog__result-row confirm-booking-dialog__result-row--phone required">
            <div className="confirm-booking-dialog__result-label">
              <i className="fas fa-mobile-alt me-2" />
              <span>電話番号</span>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <input
                className=""
                placeholder="電話番号"
                maxLength="20"
                value={this.state.phone}
                inputMode="tel"
                name="phone_number"
                // onBlur={validateOnBlur}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                  if (e.target.value) {
                    this.setState({ phoneError: "" });
                  }
                }}
              />

              {phoneError && <p className="error-text">{phoneError}</p>}
            </div>
          </div>
          <div className="confirm-booking-dialog__result-row confirm-booking-dialog__result-row--note">
            <div className="confirm-booking-dialog__result-label">
              <i className="fas fa-feather me-2"></i>
              <span>注意</span>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <textarea
                // className="form-control resize-none outline-none"
                // id="customerNote"
                rows="3"
                maxLength="500"
                onChange={(e) => this.setState({ note: e.target.value })}
              />
            </div>
          </div>
          <div className="confirm-booking-dialog__result-row">
            <div className="confirm-booking-dialog__result-label">
              <p className="estimate-amount">見積金額</p>
            </div>
            <div className="confirm-booking-dialog__result-content">
              <p>
                ¥
                {utils.formatMoney(
                  Number(userBookingData.total - userBookingData.discount),
                  0,
                  3,
                  ",",
                  "."
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="confirm-booking-dialog__confirm-btn">
          <button disabled={isBtnDisabled} onClick={createBooking}>
            確定
          </button>
        </div>
      </Modal>
    );
  }
}

export default confirmBookingModal;
