import React, { Component, useEffect, useState } from "react";
import baseContentLayout from "./../../shared/base-content/index";
import "./index.scss";
import MyButton from "../../shared/buttons/primary";
import * as actions from "./../../../modules/auth/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validateSchema from "./validate";

const Content = (props) => {
  // const globalState = window.store.getState();
  const [shopDetail, setShopDetail] = useState({});
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const { user } = window.store.getState().auth;

  useEffect(() => {
    setShopDetail(user);
  }, [user]);

  async function changeBookingConfirmStatus(e) {
    const data = {
      ...shopDetail,
      booking_confirm: e.target.checked,
    };
    updateShopInfo({ booking_confirm: data.booking_confirm }).then(() => {
      getShopInfor().then(({ data }) => {
        const { loginActions } = props;
        loginActions.setUser(data);
      });
    });
  }

  function getShopInfor() {
    return window.axios({
      url: `shop/${window.store.getState().auth.user.id}/`,
      method: "get",
    });
  }

  function updateShopInfo(data) {
    return window.axios({
      url: `shop/${window.store.getState().auth.user.id}/`,
      method: "patch",
      data,
    });
  }

  function copyLink() {
    const copyButton = document.querySelector(".common-view__copy-btn");
    copyButton.classList.remove("common-view__copy-btn--animate");
    copyButton.classList.add("common-view__copy-btn--animate");

    setTimeout(() => {
      copyButton.classList.remove("common-view__copy-btn--animate");
    }, 1000);

    let textArea = document.querySelector("textarea.js-for-copy-element");
    const link = `${process.env.REACT_APP_USER_BASE_URL}${
      window.store.getState().auth.user.id
    }/booking`;

    textArea = document.createElement("textarea");
    textArea.classList.add("js-for-copy-element");
    document.body.append(textArea);
    textArea.textContent = link;
    textArea.select();
    document.execCommand("copy");

    document.body.removeChild(textArea);
  }

  const validate = () => {
    const { error } = validateSchema.validate(shopDetail);
    let _errorMsg = {};
    let isValid = true;

    setErrorMsg(_errorMsg);

    if (error) {
      error.details.forEach((err) => {
        _errorMsg[err.context.label] = err.message;
      });

      isValid = false;
    }
    setErrorMsg(_errorMsg);

    return isValid;
  };
  const saveSetting = () => {
    setIsBtnDisabled(true);
    const isValid = validate();

    if (!isValid) {
      setIsBtnDisabled(false);
      return;
    }

    updateShopInfo({
      section_name_1: shopDetail.section_name_1,
    })
      .then(() => {
        getShopInfor().then(({ data }) => {
          const { loginActions } = props;
          loginActions.setUser(data);
        });
      })
      .finally(() => {
        setIsBtnDisabled(false);
      });
  };

  return (
    <div className="common-view">
      <div className="booking-form__row">
        <div className="booking-form__label">
          <label className="form-label user-select-none">予約承認必要？</label>
        </div>
        <div className="booking-form__control">
          <div className="form-switch">
            <input
              checked={shopDetail.booking_confirm || ""}
              className="form-check-input"
              type="checkbox"
              onChange={changeBookingConfirmStatus}
            />
          </div>
        </div>
      </div>
      <div className="booking-form__row">
        <div className="booking-form__label">
          <label className="form-label user-select-none">URL取得</label>
        </div>
        <div className="booking-form__control booking-form__control--link">
          <a
            href={`${process.env.REACT_APP_USER_BASE_URL}${
              window.store.getState().auth.user.id
            }/booking`}
            target="_blank"
            rel="noreferrer"
          >{`${process.env.REACT_APP_USER_BASE_URL}${
            window.store.getState().auth.user.id
          }/booking`}</a>
          <div className="common-view__copy-btn" onClick={copyLink}>
            <span className="material-icons">content_copy</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="booking-form__row">
        <div className="booking-form__label">
          <label className="form-label user-select-none">
            スタッフタブ名編集
          </label>
        </div>
        <div className="booking-form__control">
          <div className="common-view__input-with-count">
            <input
              className="form-control "
              value={shopDetail.section_name_1 || ""}
              maxLength="20"
              onBlur={validate}
              onChange={(e) => {
                setShopDetail({
                  ...shopDetail,
                  section_name_1: e.target.value,
                });
              }}
            />
            <span className="common-view__count">
              {shopDetail.section_name_1?.length}/20
            </span>
          </div>

          {errorMsg["section_name_1"] && (
            <p className="error-text">{errorMsg["section_name_1"]}</p>
          )}
        </div>
      </div>

      <div className="common-view__buttons">
        <MyButton
          myVariant="create"
          isLoading={isBtnDisabled}
          onClick={saveSetting}
        >
          確定
        </MyButton>
      </div>
    </div>
  );
};
const StaffContent = baseContentLayout(Content);

class index extends Component {
  render() {
    return <StaffContent name="共通設定" {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
