export const GET_SHOP_STAFF = "user/GET_SHOP_STAFF";
export const SET_SHOP_STAFF = "user/SET_SHOP_STAFF";

export const GET_SHOP_MENU = "user/GET_SHOP_MENU";
export const SET_SHOP_MENU = "user/SET_SHOP_MENU";

export const GET_SHOP_DISCOUNT = "user/GET_SHOP_DISCOUNT";
export const SET_SHOP_DISCOUNT = "user/SET_SHOP_DISCOUNT";

export const GET_SHOP_DETAIL = "user/GET_SHOP_DETAIL";
export const SET_SHOP_DETAIL = "user/SET_SHOP_DETAIL";

export const SET_USER_BOOKING_DATA = "user/SET_USER_BOOKING_DATA";
export const SET_USER_TOKEN = "user/SET_USER_TOKEN";

export const SET_USER_REFRESH_TOKEN = "user/SET_USER_REFRESH_TOKEN";

export const SET_USER_INFO = "user/SET_USER_INFO";

export const SET_CONFIRM_WHEN_REMOVE = "user/SET_CONFIRM_WHEN_REMOVE";

export const SIGN_OUT = "user/SIGN_OUT";
