import constant from "./../../core/constant";
import History from "./../../controllers/history";

const history = [
  {
    name: "history",
    path: "/history",
    component: History,
    protected: true,
    exact: true,
    type: constant.ROUTE_TYPE_ADUSER,
  },
];

export default history;
