import iconCalendar from "../../../assets/icons/ic-calendar.png";
import "./index.scss";
import React, { Component } from "react";
import moment from "moment";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DoubleCalendar from "../../shared/double-calendar";
import request from "./../../../core/api";
moment.locale("ja");

class Content extends Component {
  constructor(props) {
    super(props);

    this.dispatch = this.props.dispatchActions;
    this.mapDayLocale = [
      { jp: "月", en: "MON" },
      { jp: "火", en: "TUE" },
      { jp: "水", en: "WED" },
      { jp: "木", en: "THU" },
      { jp: "金", en: "FRI" },
      { jp: "土", en: "SAT" },
      { jp: "日", en: "SUN" },
    ];
    this.state = {
      shopId: props.match.params.id,
      dateRightCalendar: "",
      workingDays: props.user.shopDetail.workingHoursOfWeek.map(
        (item) => item.dayOfWeek
      ),
      dateLeftCalendar: "",
      chosenDate: "",
      chosenTime: "",
      timeBlocks: [],
      multiCalendarChosenDate: moment(),
    };
  }

  getDefaultFirstDateCanChose = () => {
    let today = moment();
    let todayJp = moment(today).format("dd");
    let todayEn = this.mapDayLocale.find((item) => item.jp === todayJp).en;

    //eslint-disable-next-line
    while (!this.state.workingDays.find((item) => item === todayEn)) {
      today = moment(today).add(1, "day");
      todayJp = moment(today).format("dd");

      //eslint-disable-next-line
      todayEn = this.mapDayLocale.find((item) => item.jp === todayJp).en;
    }
    return today;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const { isUserChosenTime, chosenTime } = this.props.user.userBookingData;
    const { getDefaultFirstDateCanChose } = this;
    if (!isUserChosenTime) {
      this.saveToLocalStorage({
        time: moment(chosenTime || getDefaultFirstDateCanChose()).set({
          hour: 0,
          minutes: 0,
          second: 0,
        }),
      });
    }

    this.setState(
      {
        multiCalendarChosenDate: moment(
          chosenTime || getDefaultFirstDateCanChose()
        ),
      },
      () => {
        this.fetchAvailableBookTime(
          this.state.multiCalendarChosenDate.format("YYYY-MM-DD")
        ).then(({ data }) => {
          this.setState({
            timeBlocks: data.map((item) => moment(item).format("HH:mm")),
          });
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      !moment(this.state.multiCalendarChosenDate).isSame(
        prevStates.multiCalendarChosenDate,
        "day"
      )
    ) {
      this.fetchAvailableBookTime(
        this.state.multiCalendarChosenDate.format("YYYY-MM-DD")
      ).then((result) => {
        this.setState({
          timeBlocks: result.data.map((item) => moment(item).format("HH:mm")),
        });
      });
    }
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  fetchAvailableBookTime = (date) => {
    const { chosenStaff } = this.props.user.userBookingData;

    let params = {
      date,
    };

    if (chosenStaff) {
      params.staff = chosenStaff;
    }

    return request({
      url: `shop/${this.state.shopId}/available/`,
      method: "get",
      params,
    });
  };

  disableDate = (date) => {
    const dayOfWeekJp = date.format("dd");
    const dayOfWeekEn = this.mapDayLocale.find(
      (item) => item.jp === dayOfWeekJp
    ).en;
    return !!!this.state.workingDays.find((item) => item === dayOfWeekEn);
  };

  saveToLocalStorage = ({ time, discount }, type) => {
    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenTime: time.format("YYYY-MM-DD HH:mm"),
      discount: discount || "",
    };

    if (type === 1) {
      userBookingData.isUserChosenTime = true;
    } else {
      userBookingData.isUserChosenTime = false;
    }

    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  choseTime = (item) => {
    const { chosenTime } = this.props.user.userBookingData;

    let listChosen = this.props.user.userBookingData.chosenMenu;
    let totalDiscount = "";

    if (listChosen) {
      listChosen = this.props.user.userBookingData.chosenMenu.split(",");

      totalDiscount = listChosen
        .map((chosen) =>
          this.props.user.shopMenus.find((menu) => +chosen === menu.id)
        )
        .reduce((acc, curr) => {
          const discount = this.props.user.shopDiscounts.find(
            (item) => curr.discount === item.id
          );

          let amount = acc;

          if (
            discount &&
            moment(
              moment(discount.valid_to).format("YYYY-MM-DD HH:mm")
            ).isSameOrAfter(
              moment(
                moment(chosenTime).set({
                  hour: item.split(":")[0],
                  minutes: item.split(":")[1],
                })
              ).format("YYYY-MM-DD HH:mm")
            ) &&
            moment(
              moment(discount.valid_from).format("YYYY-MM-DD HH:mm")
            ).isSameOrBefore(
              moment(
                moment(chosenTime).set({
                  hour: item.split(":")[0],
                  minutes: item.split(":")[1],
                })
              ).format("YYYY-MM-DD HH:mm")
            )
          ) {
            if (discount.type === "Percentage") {
              amount += (+discount.value * +curr.price) / 100;
            } else {
              amount += +discount.value;
            }
          }
          return amount;
        }, 0);
    }

    this.saveToLocalStorage(
      {
        time: moment(chosenTime).set({
          hour: item.split(":")[0],
          minutes: item.split(":")[1],
        }),
        discount: totalDiscount,
      },
      1
    );

    this.props.history.push(`/${this.state.shopId}/booking/`);
  };

  gotoTop = () => {
    this.props.history.push(`/${this.state.shopId}/booking/`);
  };

  multiCalendarDisabledDate = (date) => {
    const isPast = moment(date.format("YYYY-MM-DD")).isBefore(
      moment().format("YYYY-MM-DD")
    );
    const dayOfWeekJp = date.format("dd");
    const dayOfWeekEn = this.mapDayLocale.find(
      (item) => item.jp === dayOfWeekJp
    ).en;
    return (
      !!!this.state.workingDays.find((item) => item === dayOfWeekEn) || isPast
    );
  };

  multiCalendarStyledDate = (date) => {
    let className = "";
    const SATURDAY = "SAT";
    const SUNDAY = "SUN";
    if (
      this.mapDayLocale.find((item) => item.jp === date.format("dd")).en ===
      SATURDAY
    ) {
      className = "regular-holiday--saturday";
    }
    if (
      this.mapDayLocale.find((item) => item.jp === date.format("dd")).en ===
      SUNDAY
    ) {
      className = "regular-holiday--sunday";
    }

    return className;
  };

  onMultiCalendarChoseDate = (date) => {
    let listChosen = this.props.user.userBookingData.chosenMenu;
    let totalDiscount = "";

    if (listChosen) {
      listChosen = this.props.user.userBookingData.chosenMenu.split(",");

      totalDiscount = listChosen
        .map((chosen) =>
          this.props.user.shopMenus.find((menu) => +chosen === menu.id)
        )
        .reduce((acc, curr) => {
          const discount = this.props.user.shopDiscounts.find(
            (item) => curr.discount === item.id
          );

          let amount = acc;

          if (
            discount &&
            moment(
              moment(discount.valid_to).format("YYYY-MM-DD HH:mm")
            ).isSameOrAfter(
              moment(
                moment(date).set({
                  hour: 0,
                  minutes: 0,
                })
              ).format("YYYY-MM-DD HH:mm")
            ) &&
            moment(
              moment(discount.valid_from).format("YYYY-MM-DD HH:mm")
            ).isSameOrBefore(
              moment(
                moment(date).set({
                  hour: 0,
                  minutes: 0,
                })
              ).format("YYYY-MM-DD HH:mm")
            )
          ) {
            if (discount.type === "Percentage") {
              amount += (+discount.value * +curr.price) / 100;
            } else {
              amount += +discount.value;
            }
          }
          return amount;
        }, 0);
    }
    this.saveToLocalStorage({
      time: moment(date).set({
        hour: 0,
        minutes: 0,
      }),
      discount: totalDiscount,
    });

    this.setState({ multiCalendarChosenDate: date });
  };

  render() {
    const { multiCalendarChosenDate, timeBlocks } = this.state;
    const {
      choseTime,
      gotoTop,
      multiCalendarDisabledDate,
      multiCalendarStyledDate,
      onMultiCalendarChoseDate,
    } = this;
    const { chosenTime, isUserChosenTime } = this.props.user.userBookingData;

    return (
      <div
        id="user-booking-time"
        className="user-booking-time user-staffs fade-out"
      >
        <div className="user-booking-time__wrapper">
          <div className="user-staffs__ttl user-menus__ttl user-booking-time__ttl">
            <section>
              <figure>
                <img src={iconCalendar} alt="cippo-booking"></img>
              </figure>
              <p>日時選択</p>
            </section>
            <span className="user-menus__btn-return-top" onClick={gotoTop}>
              TOPへ戻る
            </span>
          </div>
          <div className="user-staffs__ctn-wrapper user-booking-time__ctn-wrapper">
            <DoubleCalendar
              className="user-booking-time__calendar"
              disabledDate={multiCalendarDisabledDate}
              styledDate={multiCalendarStyledDate}
              value={moment()}
              chosenDate={multiCalendarChosenDate}
              numberOfCalendar={2}
              onChoseDate={onMultiCalendarChoseDate}
            />
            <div className="user-booking-time__hint">
              <div>
                <span className="material-icons">crop_16_9</span>
                <p>当日</p>
              </div>
              <div>
                <span className="material-icons">crop_16_9</span>
                <p>選択した日付・時間</p>
              </div>
            </div>

            <div className="user-booking-time__block-times">
              <div className="selected-date">
                {moment(chosenTime).format("YYYY/MM/DD (dd)")}
              </div>
              <ul>
                {timeBlocks.map((item) => (
                  <li
                    className={
                      isUserChosenTime &&
                      item === moment(chosenTime).format("HH:mm")
                        ? "selected"
                        : ""
                    }
                    key={item}
                    onClick={() => choseTime(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
