import NotMatch from "./../../controllers/notmatch";

const routes = [
  {
    path: "/404",
    component: NotMatch,
    exact: true,
    // reducer,
  },
];

export default routes;
