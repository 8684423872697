import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./../../modules/auth/actions";
// import Loader from "./loader";
import request from "./../../core/api";
import "./index.scss";

class Login extends React.Component {
  componentDidMount() {
    const { loginActions } = this.props;
    let query = this.props.match.params.id;
    const oldId = query.substring(query.lastIndexOf("-") + 1, query.length);
    const refreshToken = query.substring(0, query.lastIndexOf("-"));

    this.refresh(refreshToken).then((result) => {
      const { access, refresh } = result.data;

      loginActions.setToken(access);
      loginActions.setRefreshToken(refresh);
      loginActions.getShopInfo(oldId);
    });
  }

  refresh = (refreshToken) => {
    return request({
      url: `token/refresh/`,
      method: "post",
      data: {
        refresh: refreshToken,
      },
    });
  };

  componentDidUpdate({ auth }) {
    if (
      this.props.auth.token &&
      !window.utils.isEmptyObject(this.props.auth.user)
    ) {
      this.props.history.replace("/booking");
    }
  }

  render() {
    return (
      <div id="redirect" className="redirect">
        <div className="loader-wrapper">
          <div className="loader">Loading...</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
