import * as React from "react";
import Modal from "./../../../shared/dialog";
import "./index.scss";
import MyButton from "./../../../shared/buttons/primary";

const index = (props) => {
  return (
    <Modal
      className={"guide-modal"}
      title={""}
      isShow={true}
      closeByEscKey={false}
      showCloseButton={false}
      onCloseRequest={() => props.onClose()}
      closeOnOverlayClick={false}
    >
      <pre>
        {`予約履歴は
サブコレアプリからご予約の方は
アプリ下「予約一覧」の「美容予約」
にも表示されます。`}
      </pre>

      <div className="guide-modal__btn-area">
        <MyButton myVariant="detail" onClick={() => props.onClose()}>
          OK
        </MyButton>
      </div>
    </Modal>
  );
};

export default index;
