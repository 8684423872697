import iconBook from "../../../assets/icons/ic-book.png";
import iconUser from "../../../assets/icons/ic-user.png";
import iconCalendar from "../../../assets/icons/ic-calendar.png";
import "./index.scss";
import moment from "moment";
import utils from "../../../core/utils";
import constant from "../../../core/constant";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginModal from "./components/loginModal";
import ConfirmBookingModal from "./components/confirmBookingModal";
import ConfirmRemoveModal from "./components/confirmRemoveModal";
import React, { Component } from "react";
import GoToHistoryButton from "./../../shared/top-button";

const removeDialog = {
  menu: "menu",
  staff: "staff",
  time: "time",
};

class Content extends Component {
  constructor(props) {
    super(props);

    this.dispatch = props.dispatchActions;

    this.state = {
      listStaff: [],
      listDiscount: [],
      listMenu: [],
      shopId: props.match.params.id,
      errorMessages: [],
      isShowLoginModal: false,
      userAccount: "",
      userPassword: "",
      isShowConfirmBookingModal: false,
      isShowRemoveDialog: false,
      removeType: "",
      willRemoveThing: "",
    };
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  remove = (type) => {
    this.setState({ isShowRemoveDialog: true, removeType: type });
  };

  removeMenu = (menu) => {
    if (this.props.user.confirmWhenRemove === true) {
      this.setState({
        isShowRemoveDialog: true,
        removeType: removeDialog.menu,
        willRemoveThing: menu,
      });

      return;
    }

    const listChosen = this.props.user.userBookingData.chosenMenu.split(",");
    const index = listChosen.findIndex((item) => +item === menu.id);
    listChosen.splice(index, 1);

    const totalMoney = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.price));
      }, 0);

    const totalDuration = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.duration));
      }, 0);

    const totalDiscount = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        const discount = this.props.user.shopDiscounts.find(
          (item) => curr.discount === item.id
        );

        let amount = acc;

        if (discount) {
          if (discount.type === "Percentage") {
            amount += (+discount.value * +curr.price) / 100;
          } else {
            amount += +discount.value;
          }
        }
        return amount;
      }, 0);

    const userBookingData = {
      ...this.props.user.userBookingData,
      total: totalMoney,
      duration: totalDuration,
      discount: totalDiscount,
      chosenMenu: listChosen.join(","),
    };

    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  removeStaff = (staff) => {
    if (this.props.user.confirmWhenRemove === true) {
      this.setState({
        isShowRemoveDialog: true,
        removeType: removeDialog.staff,
        willRemoveThing: staff,
      });

      return;
    }

    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenStaff: "",
    };
    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  removeChosenTime = (chosenTime) => {
    if (this.props.user.confirmWhenRemove === true) {
      this.setState({
        isShowRemoveDialog: true,
        removeType: removeDialog.time,
        willRemoveThing: chosenTime,
      });

      return;
    }

    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenTime: "",
      isUserChosenTime: false,
    };
    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });
  };

  changePage = (path) => {
    this.props.history.push(path);
  };

  onCloseLoginModal = (data) => {
    this.setState({
      isShowLoginModal: false,
    });
    if (data && data.loginSuccess === true) {
      this.setState({
        isShowConfirmBookingModal: true,
      });
    }
  };

  onCloseConfirmBookingModal = async (bookingId) => {
    this.setState({ isShowConfirmBookingModal: false });
    if (!bookingId) {
      return;
    }
    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: {
        chosenMenu: "",
        total: "",
        discount: "",
        duration: "",
        chosenTime: "",
        isUserChosenTime: false,
      },
    });

    this.props.history.push(
      `/${this.state.shopId}/booking/${bookingId}/booking-success`
    );
  };

  onConfirmButtonClick = () => {
    if (!this.props.user.userToken) {
      this.setState({ isShowLoginModal: true });
      return;
    }

    this.setState({ isShowConfirmBookingModal: true });
  };

  onCloseConfirmRemoveModal = (data) => {
    this.setState({ isShowRemoveDialog: false, removeType: "" });
  };

  gotoHistory = () => {
    this.props.history.push(`/${this.props.match.params.id}/booking/history`);
  };

  render() {
    const {
      shopMenus,
      shopDiscounts,
      shopStaffs,
      userBookingData,
      userToken,
      shopDetail,
    } = this.props.user;
    const {
      total,
      discount,
      chosenStaff,
      chosenMenu: chosenMenuIds,
      chosenTime,
      isUserChosenTime,
    } = this.props.user.userBookingData;

    const {
      isShowLoginModal,
      isShowConfirmBookingModal,
      isShowRemoveDialog,
      removeType,
      willRemoveThing,
    } = this.state;
    const {
      removeMenu,
      removeChosenTime,
      onCloseConfirmBookingModal,
      onConfirmButtonClick,
      onCloseConfirmRemoveModal,
      removeStaff,
      gotoHistory,
    } = this;

    let chosenMenu = [];
    if (userBookingData.chosenMenu) {
      chosenMenu = userBookingData.chosenMenu
        .split(",")
        .map((item) => shopMenus.find((menu) => menu.id === +item));

      chosenMenu = chosenMenu.map((menu) => {
        const discount = shopDiscounts.find(
          (discount) => discount.id === menu.discount
        );

        if (
          !discount ||
          !(
            moment(
              moment(discount.valid_to).format("YYYY-MM-DD HH:mm")
            ).isSameOrAfter(
              moment(chosenTime || new Date()).format("YYYY-MM-DD HH:mm")
            ) &&
            moment(
              moment(discount.valid_from).format("YYYY-MM-DD HH:mm")
            ).isSameOrBefore(
              moment(chosenTime || new Date()).format("YYYY-MM-DD HH:mm")
            )
          )
        ) {
          return menu;
        }

        let priceAfterDiscount = "";
        if (discount.type === constant.discountType.PERCENTAGE) {
          priceAfterDiscount =
            menu.price - (menu.price * Number(discount.value)) / 100;
        } else {
          priceAfterDiscount = menu.price - Number(discount.value);
        }

        return {
          ...menu,
          priceAfterDiscount,
        };
      });
    }

    const canPay = chosenStaff && chosenMenuIds && isUserChosenTime;

    return (
      <div id="user-booking-home" className="user-home fade-out">
        <div className="user-home__wrapper">
          <p className="user-home__ttl">予約オプションを選択する</p>
          {shopDetail.booking_confirm ? (
            <p className="user-home__text-hint">
              店舗からお客さまへ予約受付の可否し、確認後に予約が確定します。
            </p>
          ) : (
            ""
          )}
          {userToken ? (
            <GoToHistoryButton
              buttonText={"予約履歴へ"}
              onClick={gotoHistory}
            />
          ) : (
            ""
          )}

          <div className="user-home__ctn-wrapper">
            <ul className="user-home__options options">
              <li className="options__item">
                <div className="options__header">
                  <figure>
                    <img src={iconUser} alt="cippo-booking"></img>
                  </figure>
                  <p className="options__label">
                    {window.store.getState().user.shopDetail.section_name_1}
                  </p>
                </div>
                {!chosenStaff ? (
                  <div className="options__description">
                    {window.store.getState().user.shopDetail.section_name_1}
                    を選択してください
                  </div>
                ) : (
                  <div className="options__chosen-menu">
                    <span
                      className="material-icons close-btn"
                      onClick={() =>
                        removeStaff(
                          shopStaffs.find((staff) => staff.id === chosenStaff)
                        )
                      }
                    >
                      close
                    </span>
                    <p className="staff-name">
                      {
                        shopStaffs.find((staff) => staff.id === chosenStaff)
                          .name
                      }
                    </p>
                  </div>
                )}
                <span
                  className="options__chose-btn"
                  onClick={() =>
                    this.changePage(
                      `/${this.props.match.params.id}/booking/staffs`
                    )
                  }
                >
                  選択する
                </span>
              </li>
              <li className="options__item">
                <div className="options__header">
                  <figure>
                    <img src={iconBook} alt="cippo-booking"></img>
                  </figure>
                  <p className="options__label">メニュー</p>
                </div>
                {!chosenMenu.length ? (
                  <div className="options__description">
                    メニューを選択してください
                  </div>
                ) : (
                  chosenMenu.map((menu) => (
                    <div
                      style={{ marginBottom: "6px" }}
                      key={menu.id}
                      className="options__description"
                    >
                      <div className="options__chosen-menu">
                        <span
                          className="material-icons close-btn"
                          onClick={() => removeMenu(menu)}
                        >
                          close
                        </span>
                        <span className="name">{menu.name}</span>
                        <div className="time">
                          <span className="material-icons">schedule</span>
                          <p>{menu.duration}分</p>
                        </div>
                        <div className="price">
                          ¥
                          {window.utils.formatMoney(
                            Number(menu.priceAfterDiscount || menu.price),
                            0,
                            3,
                            ",",
                            "."
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <span
                  className="options__chose-btn"
                  onClick={() =>
                    this.changePage(
                      `/${this.props.match.params.id}/booking/menus`
                    )
                  }
                >
                  選択する
                </span>
                {chosenMenu.length ? (
                  <div className="options__total">
                    合計 ¥
                    {utils.formatMoney(
                      Number(total - discount),
                      0,
                      3,
                      ",",
                      "."
                    )}
                  </div>
                ) : (
                  ""
                )}
              </li>
              <li className="options__item">
                <div className="options__header">
                  <figure>
                    <img src={iconCalendar} alt="cippo-booking"></img>
                  </figure>
                  <p className="options__label last-one">日時</p>
                </div>
                {!isUserChosenTime ? (
                  <div className="options__description">
                    日時を選択してください
                  </div>
                ) : (
                  <div className="options__chosen-menu">
                    <span
                      className="material-icons close-btn"
                      onClick={() => removeChosenTime(chosenTime)}
                    >
                      close
                    </span>
                    <p className="staff-name">
                      {moment(chosenTime).format("YYYY/MM/DD (dd) HH:mm ~")}
                    </p>
                  </div>
                  // <div className="options__description">
                  //   <p className="staff-name">{moment(chosenTime).format()}</p>
                  // </div>
                )}
                <span
                  className="options__chose-btn"
                  onClick={() =>
                    this.changePage(
                      `/${this.props.match.params.id}/booking/time`
                    )
                  }
                >
                  選択する
                </span>
              </li>
            </ul>

            <div className="user-home__cart">
              <div className="cart-wrapper">
                {/* <ul className="user-home__cart-items">
                  <li></li>
                </ul> */}
                <div className="user-home__cart-money">
                  <span>見積金額</span>
                  <span>
                    ¥
                    {total || discount
                      ? window.utils.formatMoney(
                          total - discount,
                          0,
                          3,
                          ",",
                          "."
                        )
                      : 0}
                  </span>
                </div>
              </div>
              <button
                className="user-home__confirm-btn"
                disabled={!canPay}
                onClick={onConfirmButtonClick}
              >
                確定
              </button>
            </div>
          </div>
        </div>
        {isShowLoginModal ? (
          <LoginModal
            onClose={this.onCloseLoginModal}
            {...this.props}
          ></LoginModal>
        ) : (
          ""
        )}
        {isShowConfirmBookingModal ? (
          <ConfirmBookingModal
            onClose={onCloseConfirmBookingModal}
            {...this.props}
          />
        ) : (
          ""
        )}
        {isShowRemoveDialog ? (
          <ConfirmRemoveModal
            type={removeType}
            data={willRemoveThing}
            onClose={onCloseConfirmRemoveModal}
            {...this.props}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
