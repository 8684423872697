import React, { Component } from "react";
import "./index.scss";

const higherOrderComponent = (WrappedComponent) => {
  class HOC extends Component {
    render() {
      return (
        <div className="base-content">
          <div className="wrapper">
            <header className="base-content__ttl">
              <h2>{this.props.name}</h2>
            </header>
            <div className="base-content__main custom-scrollbar-1">
              <WrappedComponent {...this.props} />
            </div>
          </div>
        </div>
      );
    }
  }
  return HOC;
};

export default higherOrderComponent;
