import reducer from "./../../modules/setting/reducer";
import sagas from "./../../modules/setting/sagas";
import constant from "./../../core/constant";

import Staff from "./../../controllers/setting/staff";
import Menu from "./../../controllers/setting/menu";
import Common from "./../../controllers/setting/common";
import Discount from "./../../controllers/setting/discount";

const routes = [
  {
    name: "setting",
    path: "/setting/staff",
    component: Staff,
    protected: true,
    reducer,
    sagas,
    type: constant.ROUTE_TYPE_ADUSER,
  },
  {
    name: "setting",
    path: "/setting/menu",
    component: Menu,
    protected: true,
    type: constant.ROUTE_TYPE_ADUSER,
  },
  {
    name: "setting",
    path: "/setting/common",
    component: Common,
    protected: true,
    type: constant.ROUTE_TYPE_ADUSER,
  },
  {
    name: "setting",
    path: "/setting/discount",
    component: Discount,
    protected: true,
    type: constant.ROUTE_TYPE_ADUSER,
  },
];

export default routes;
