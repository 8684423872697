import Joi from "joi";
import isEmpty from "lodash/isEmpty";

const defaultSchemaObj = {
  username: Joi.string()
    .required()
    .regex(/[$]/, { invert: true })
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "ユーザー名を入力してください。";
            break;
          case "string.pattern.invert.base":
            err.message = "半角英字、数字、記号($以外)を使用することができます";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  email: Joi.string()
    .required()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "メールアドレスを入力してください。";
            break;
          case "string.pattern.base":
            err.message = "メールフォーマットが正しくありません。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  password: Joi.string()
    .required()
    // .regex(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,16}$/)
    .regex(
      /^(?=.*[a-z])(?=.*\d)[><?@+'`~^%&\\*\\[\]\\{\\}.!#|\\\\"$';,:;=/\\(\\),\-\w]{8,16}$/
    )
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message =
              "英文字、数字含めて８文字以上16文字以下を入力してください。";
            break;
          case "string.pattern.base":
            err.message =
              "英文字、数字含めて８文字以上16文字以下を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  confirm_password: Joi.any()
    .equal(Joi.ref("password"))
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.only":
            err.message = "パスワードが一致しません。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  first_name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "姓が必須です。";
            break;

          default:
            break;
        }
      });
      return errors;
    }),
  last_name: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "名が必須です。";
            break;

          default:
            break;
        }
      });
      return errors;
    }),
  confirmed: Joi.boolean()
    .valid(true)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.only":
            err.message = "ご利用規約に同意してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
};

const getSchema = (key) => {
  const filteredSchemaObj = {};

  if (key) {
    filteredSchemaObj[key] = defaultSchemaObj[key];
  }

  const object = isEmpty(filteredSchemaObj)
    ? defaultSchemaObj
    : filteredSchemaObj;

  const schema = Joi.object(object).options({
    abortEarly: false,
    allowUnknown: true,
  });

  return schema;
};

export default getSchema;
